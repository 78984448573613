import * as s from './style_personalizavel'

/**
 * 
 * @param {titulo} titulo título do input 
 * @param {resProps} resProps todos os detalhes do input como id, class e etc
 * @param {children} children todos os detalhes dentro do select e textarea
 * @param {className} className cpf_, cnpj_, cep_, fone_, button__
 * @returns 
 */
export default function InputKweb({ titulo, children, corborder, axecao, acaoBotao = null, cortext, ...resProps }) {

    //formatar CPF
    function formataCPF(cpf) {
        //retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");

        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    function validarCPF_(strCPF) {
        strCPF = (new String(strCPF)).replace(/[^0-9]/g, '');
        var Soma;
        var Resto;
        Soma = 0;
        if ((strCPF == "") || (strCPF == null) || (strCPF == undefined)
            || (strCPF == "00000000000") || (strCPF == "11111111111")
            || (strCPF == "22222222222") || (strCPF == "33333333333")
            || (strCPF == "44444444444") || (strCPF == "55555555555")
            || (strCPF == "66666666666") || (strCPF == "77777777777")
            || (strCPF == "88888888888") || (strCPF == "99999999999")) {
            return false;
        }

        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) {
            Resto = 0;
        }
        if (Resto != parseInt(strCPF.substring(9, 10))) {
            return false;
        }

        Soma = 0;
        for (let i = 1; i <= 10; i++) {
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        }
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) {
            Resto = 0;
        }
        if (Resto != parseInt(strCPF.substring(10, 11))) {
            return false;
        }
        return true;
    }
    //formatar CNPJ
    function formatarCNPJ(cnpj) {
        return cnpj.replace(/[^\d]+/g, '').replace(/^(.{0,2})(.{0,3})(.{0,3})(.{0,4})(.{0,2}).*$/g, function (m, p1, p2, p3, p4, p5) {
            return p1 + (p2 ? '.' + p2 : '') + (p3 ? '.' + p3 : '') + (p4 ? '.' + p4 : '') + (p5 ? '-' + p5 : '');
        });
    }
    function validarCNPJ_(str) {
        let cnpj = (new String(str)).replace(/[^0-9]/g, '');
        var numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;
        digitos_iguais = 1;
        if (cnpj.length < 14 && cnpj.length < 15)
            return false;
        for (i = 0; i < cnpj.length - 1; i++)
            if (cnpj.charAt(i) != cnpj.charAt(i + 1)) {
                digitos_iguais = 0;
                break;
            }
        if (!digitos_iguais) {
            tamanho = cnpj.length - 2
            numeros = cnpj.substring(0, tamanho);
            digitos = cnpj.substring(tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;
            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;
            return true;
        } else
            return false;
    }
    //formatar Real
    function formatarReal(i) {
        var v = i.replace(/\D/g, '');
        v = (v / 100).toFixed(2) + '';
        v = v.replace(".", ",");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        return v;
    }
    //formatar CEP
    function formatarCEP(cep) {
        return cep.replace(/[^\d]+/g, '').replace(/^(.{0,5})(.{0,3}).*$/g, function (m, p1, p2) {
            return p1 + (p2 ? '-' + p2 : '');
        });
    }
    //formatar FONE
    function formatarFONE8(fone) {
        return fone.replace(/[^\d]+/g, '').replace(/^(.{0,4})(.{0,4}).*$/g, function (m, p1, p2) {
            return p1 + (p2 ? '-' + p2 : '');
        });
    }
    function formatarFONE9(fone) {
        return fone.replace(/[^\d]+/g, '').replace(/^(.{0,5})(.{0,4}).*$/g, function (m, p1, p2) {
            return p1 + (p2 ? '-' + p2 : '');
        });
    }
    function formatarFONE10(fone) {
        return fone.replace(/[^\d]+/g, '').replace(/^(.{0,2})(.{0,4})(.{0,4}).*$/g, function (m, p1, p2, p3) {
            return '(' + p1 + ')' + (p2 ? p2 : '') + (p3 ? '-' + p3 : '');
        });
    }
    function formatarFONE11(fone) {
        return fone.replace(/[^\d]+/g, '').replace(/^(.{0,2})(.{0,5})(.{0,4}).*$/g, function (m, p1, p2, p3) {
            return '(' + p1 + ')' + (p2 ? p2 : '') + (p3 ? '-' + p3 : '');
        });
    }
    //formatar HORA
    function formatarHora(hora) {
        return hora.replace(/[^\d]+/g, '').replace(/^(.{0,2})(.{0,2}).*$/g, function (m, p1, p2) {
            return p1 + (p2 ? ':' + p2 : '');
        });
    }

    //formatar input
    function formatarInput(e) {
        let formatar = e?.className;
        let valor = e?.value;
        let id = e?.id;

        if (formatar.indexOf('cpf_cnpj_') >= 0) {//CPF ou CNPJ
            if (valor.length > 10) {
                valor = (new String(valor)).replace(/[^0-9]/g, '');
                if (valor.length == 11) {//CPF
                    //validar cpf
                    if (validarCPF_(valor)) {
                        e.style.cssText = 'color: #008000';
                    } else {
                        e.style.cssText = 'color: #ff0000';
                    }

                    return formataCPF(valor);
                }
                if (valor.length == 14) {//CNPJ
                    //validar CNPJ
                    if (validarCNPJ_(valor)) {
                        e.style.cssText = 'color: #008000';
                    } else {
                        e.style.cssText = 'color: #ff0000';
                    }

                    return formatarCNPJ(valor);
                }
            }
        } else if (formatar.indexOf('cpf_') >= 0) {//CPF
            if (valor.length > 10) {
                valor = (new String(valor)).replace(/[^0-9]/g, '');
                if (valor.length == 11) {
                    //validar cpf
                    if (validarCPF_(valor)) {
                        e.style.cssText = 'color: #008000';
                    } else {
                        e.style.cssText = 'color: #ff0000';
                    }

                    return formataCPF(valor);
                }
            }
        } else if (formatar.indexOf('cnpj_') >= 0) {//CNPJ
            if (valor.length > 13) {
                valor = (new String(valor)).replace(/[^0-9]/g, '');
                if (valor.length == 14) {
                    //validar CNPJ
                    if (validarCNPJ_(valor)) {
                        e.style.cssText = 'color: #008000';
                    } else {
                        e.style.cssText = 'color: #ff0000';
                    }

                    return formatarCNPJ(valor);
                }
            }
        } else if (formatar.indexOf('cep_') >= 0) {//CEP
            if (valor.length > 7) {
                valor = (new String(valor)).replace(/[^0-9]/g, '');
                if (valor.length == 8) {
                    return formatarCEP(valor);
                }
            }
        } else if (formatar.indexOf('real_') >= 0) {//REAL
            return formatarReal(valor);
        } else if (formatar.indexOf('hora_') >= 0) {//hora
            if (valor.length > 2) {
                valor = (new String(valor)).replace(/[^0-9]/g, '');
                return formatarHora(valor);
            }
        } else if (formatar.indexOf('fone_') >= 0) {//FONE
            if (valor.length > 7) {
                valor = (new String(valor)).replace(/[^0-9]/g, '');
                if (valor.length == 8) {
                    return formatarFONE8(valor);
                } else
                    if (valor.length == 9) {
                        return formatarFONE9(valor);
                    } else
                        if (valor.length == 10) {
                            return formatarFONE10(valor);
                        } else
                            if (valor.length == 11) {
                                return formatarFONE11(valor);
                            }
            }
        }
        return e.value;
    }




    return (
        <s.ContainerInputFloat axecao={axecao != undefined ? true : false}>
            {
                resProps.type == "checkbox" ? (
                    <fieldset className={`label-checkbox ${corborder != undefined ? corborder : ''}`}>
                        {
                            titulo != undefined &&
                            <legend legend > <p className={cortext != undefined ? cortext : ''}>{titulo}</p></legend>
                        }
                        <input {...resProps} />
                    </fieldset>
                ) :
                    resProps.type == "button" ? (
                        <div id='container__input__button'
                            onClick={() => acaoBotao != null ? acaoBotao() : {}}>
                            <input
                                {...resProps}
                                type='text'
                                readOnly
                            />
                        </div>
                    ) :

                        <div className="label-float">
                            {

                                resProps.type == "textarea" ? (
                                    <textarea {...resProps} placeholder=" " >
                                        {children}
                                    </textarea>
                                ) :
                                    resProps.type == "select" ? (
                                        <select {...resProps} placeholder=" " >
                                            {children}
                                        </select>
                                    ) :
                                        resProps.type == "date" ? (
                                            <input
                                                {...resProps}
                                                placeholder=" "
                                            />
                                        ) :
                                            resProps.type == "datetime-local" ? (
                                                <input
                                                    {...resProps}
                                                    placeholder=" "
                                                />
                                            ) :
                                                resProps.type == "number" ? (
                                                    <input
                                                        {...resProps}
                                                        placeholder=" "
                                                    />
                                                ) :
                                                    (
                                                        <input
                                                            onKeyUp={(e) => {
                                                                e.target.className && (e.target.value = formatarInput(e.target))
                                                            }}
                                                            {...resProps}
                                                            placeholder=" "
                                                        />
                                                    )}
                            {
                                titulo != undefined &&
                                <label>{titulo}</label>
                            }
                        </div>
            }
        </s.ContainerInputFloat>

    );
}