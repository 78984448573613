import styled from "styled-components";

export const ContainerPesquisa = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 250px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${p=> p.cor ? p.cor : '#469CAC'};
        width: 35px;
        height: 35px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        cursor: pointer;

        svg {
            color: #fff;
        }
    }

    input {
        width: 100%;
        height: 35px;
        background-color: #fff;
        padding: 5px 10px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        outline: none;
        border: none;
        box-shadow: 2px 2px 2px #d9d9d9;

        @media only screen and (max-width: 700px){
            width: 100%;
        }
    }

`;
