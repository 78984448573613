
import * as s from './estiloContainerGlobal'
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import Sidebar from '../sidebar/sidebar';
import NavBar from '../navibar/navbar';
import { ToastContainer } from 'react-toastify';
import { SpinnerLoad } from '../SpinnerGlobal/spinner';
import Clientes from '../../pages/clientes/clientes';
import ComponenteSelecionarClientes from '../clientes/compClientes';
import ComponenteSelecionarProdutos from '../modal_produtos/compProdutos';
import ComponenteSelecionarVeiculos from '../modal_veiculos/compVeiculos';

export default function ContainerGlobal({ children }) {
    const { signed, deslogar, pesquisaAtiva, setPesquisaInputsNavbar, modalListaClientes,  modalListaVeiculos, user } = useContext(AuthContext)
    const [statusSidebar, setStatusSidebar] = useState(false);

    return (
        <s.ContainerGeral
            className='elemento'
        >

            <SpinnerLoad />
            <ToastContainer position="bottom-right" pauseOnHover={false} />
            <s.MainNavSidebar  >
                {
                    signed && statusSidebar &&
                    <Sidebar
                        statusSidebar={statusSidebar}
                        setStatusSidebar={setStatusSidebar}
                        deslogar={deslogar}
                        user={user}
                    />
                }
                <s.Main>
                    {
                        signed &&
                        <NavBar
                            statusSidebar={statusSidebar}
                            setStatusSidebar={setStatusSidebar}
                            pesquisaAtiva={pesquisaAtiva}
                            setPesquisa={setPesquisaInputsNavbar}
                        />
                    }
                    {children}
                </s.Main>

                {
                    modalListaClientes &&
                    <s.ContainerModal>
                        <ComponenteSelecionarClientes />
                    </s.ContainerModal>
                }
                {
                    modalListaVeiculos &&
                    <s.ContainerModal>
                        <ComponenteSelecionarVeiculos />
                    </s.ContainerModal>
                }
            </s.MainNavSidebar>

        </s.ContainerGeral>
    );
}