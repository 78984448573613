import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloContratos'
import api from '../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import * as Fa from "react-icons/fa";
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import MenuAcoes from '../../components/menu_acoes/menu_acoes';
import ModalOpcoesDinamico from '../../components/ModalOpcoesDinamico/modalDinamico';
import CompAdicionarVenda from './CompNovaVenda/comp_nova_venda';
import Pesquisa from '../../components/pesquisa/pesquisa';
import ReactModal from 'react-modal';
import Modal from '../../components/Modal/ModalPers';
import { dataExtenso } from '../../functions/fucoes';

export default function Contratos() {
  const { Spinner, setClienteSelecionado } = useContext(AuthContext);
  const [vendaSelecionada, setVendaSelecionada] = useState(null);
  const [listaContratos, setListaContratos] = useState([]);
  const [adicionarVenda, setAdicionarVenda] = useState(false);
  const [tipo, setTipo] = useState(2);
  const [Mdpagamentos, setMdpagamentos] = useState(false);
  const [MdDispositivos, setMdDispositivos] = useState(false);
  const [MdVeiculos, setMdVeiculos] = useState(false);
  const [MdDetalhes, setMdDetalhes] = useState(false);
  const [listVeiculos, setlistVeiculos] = useState([]);
  const [listDispositivos, setlistDispositivos] = useState([]);
  const [listPagamentos, setListPagamentos] = useState([]);


  const cor = ['#17A398', '#064789', '#C32C56']

  useEffect(() => {
    if (vendaSelecionada) {
      for (const key in vendaSelecionada) {
        const valor = vendaSelecionada[key];
        try { document.getElementById(key).value = valor } catch (error) { null }
      }
    }

    return () => { setClienteSelecionado(null) }
  }, [vendaSelecionada])

  useEffect(() => {
    if (!adicionarVenda) {
      setVendaSelecionada(null)
      buscarContratos()
    }

  }, [adicionarVenda])

  async function buscarContratos() {
    Spinner(true)
    setListaContratos([])

    try {
      const res = await api.get(`/contratos`)
      if (res.data.status) {
        setListaContratos(res.data.resultado)
        Spinner(false)
      } else {
        setListaContratos([])
        AlertaDeMensagem('alerta', 'Não existe nenhum produto cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
        Spinner(false)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }

  async function pagamentos(id) {
    setListPagamentos([]);
    Spinner(true);
    try {
      const pay = await api.get(`pagamentos/idcontrato/${id}`);
      if (pay.data.status) {
        let dadosPagamento = pay.data.resultado;
        setListPagamentos(dadosPagamento);
      } else {
        AlertaDeMensagem('alerta', 'Nenhum resultado retornado', 'ALERTA', 3000);
      }
    } catch (error) {
      AlertaDeMensagem('erro', 'Erro ao buscar pagamentos', 'ERRO', 3000);
    } finally {
      Spinner(false);
    }
  }


  async function gravar(tipo) {
    const arrayGeral = [];
    let contratoList = document.querySelectorAll(`.contrato [name]`)
    let contrato = null

    for (const el of contratoList) {
      if (el.id != 'nome_selected') {
        contrato = {
          ...contrato,
          [el.id]: el.value
        }
      }
    }

    let ele_ids = document.querySelectorAll(`.id_Veiculo`)
    for (const els of ele_ids) {
      const arrayIdProdts = [];
      let custTotal = 0;
      let produtsVinc = document.querySelectorAll(`.ids_produtos${els.id}`)
      for (const idsProds of produtsVinc) {
        let objPrd = JSON.parse(idsProds.value)
        arrayIdProdts.push
          ({
            id_veiculo: els.id,
            id_produto: objPrd.id_produto
          })
        custTotal += parseFloat(objPrd.custo)
      }
      arrayGeral.push(
        {
          ...contrato,
          array_veiculos: arrayIdProdts,
          [tipo == 'anual' ? 'valor_mensal' : 'valor_anual']: 0
        }
      )
    }

    if (arrayGeral.length > 0) {
      Spinner(true)

      for (const key in arrayGeral) {

        const objDds = arrayGeral[key];
        try {

          const res = await api.post(`/contratos/inserir`, objDds)

          if (res.data.status) {
            setAdicionarVenda(false)
            buscarContratos()
          } else {
            AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
          }

        } catch (error) {
          console.log('error :>> ', error);
          AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
          Spinner(false)
        }

      }
      Spinner(false)

    } else {
      Spinner(false)
      AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
    }

  }

  function functionCor(index) {
    const cor = ['#2e4a66', '#a98921', '#469cac', '#3b9668'];
    if (index % 4 == 0) {
      return cor[0];
    }
    else if (index % 4 == 1) {
      return cor[1];
    }
    else if (index % 4 == 2) {
      return cor[2];
    }
    else if (index % 4 == 3) {
      return cor[3];
    }
  }

  function CardContratos({ valores }) {
    const [abrirComponent, setAbrirComponent] = useState(false);
    const [listaProdutosCliente, setListaProdutosCliente] = useState([]);
    const {
      MdDetalhes,
      Mdpagamentos,
      MdVeiculos,
      MdDispositivos,
      listPagamentos,
      pagamentos,
      setMdDetalhes,
      setMdpagamentos,
      setMdVeiculos,
      setMdDispositivos,
      c,
      index
    } = valores


    return (
      <s.ContainerCard
        cor={functionCor(index)}
        key={c.id_cliente}
      >
        <s.ContainerDados
          cor={functionCor(index)}
          key={c.id_cliente}
        >
          <s.Greeting>
            <h1>
              Contrato {c.tipo_locacao == 'mensal' ? `Mensal` : `Anual`} Código : {c.id_contrato}
            </h1>
            <p>Cliente : {c.nome} {c.sobrenome}</p>
            <p>Código do cliente : {c.id_cliente}</p>
          </s.Greeting>
        </s.ContainerDados>

        <s.ContainerBotoes>

          <s.BotoesCard
            cor={functionCor(index)}
            onClick={() => {
              setMdDetalhes(true);
            }
            }

          >
            <Fa.FaBoxes
              className="fa fa-times"
              id="icon-conar"
              aria-hidden="true"
            />
            <h6>Detalhes</h6>
          </s.BotoesCard>


          <s.BotoesCard
            cor={functionCor(index)}
            onClick={() => {
              AlertaDeMensagem('sucesso' , '' , 'EM CONSTRUÇÃO' , 3000)
            }
            }
          >
            <Fa.FaBoxes
              className="fa fa-times Icon"
              id="Icon"
              aria-hidden="true"
            />
            <h6>Dispositivos</h6>
          </s.BotoesCard>


          <s.BotoesCard
            cor={functionCor(index)}
            onClick={() => {
              setMdpagamentos(true);
              pagamentos(c.id_contrato);
            }
            }
          >
            <Fa.FaBoxes
              className="fa fa-times Icon"
              id="Icon"
              aria-hidden="true"
            />
            <h6>Pagamentos</h6>
          </s.BotoesCard>


          <s.BotoesCard
            cor={functionCor(index)}
            onClick={() =>
              AlertaDeMensagem('sucesso' , '' , 'EM CONSTRUÇÃO' , 3000)
            }
          >
            <Fa.FaCar
              className="fa fa-times Icon"
              id="Icon"
              aria-hidden="true"
            />
            <h6>Veiculos</h6>
          </s.BotoesCard>

        </s.ContainerBotoes>

        <Modal
          exibir={MdDetalhes}
          setStateExibir={setMdDetalhes}
        >
          <s.ContainerModDin>
            <h1>DETALHES DO CONTRATO</h1>
            <p> Nome : {c.nome} {c.sobrenome}</p>
            <p> Inicio : {dataExtenso(c.data_inicio, '/')}</p>
            <p> Final : {dataExtenso(c.data_fim, '/')}</p>
            <p>
              Valor {c.tipo_locacao == 'mensal' ? 'Mensal' : 'Anual'} : {
                c.tipo_locacao == 'mensal' ?
                  c.valor_mensal : c.valor_anual
              }
            </p>
            <p>status : {c.status}</p>
            <p>{c.profissao}</p>
          </s.ContainerModDin>
        </Modal>

        <Modal
          exibir={MdDispositivos}
          setStateExibir={setMdDispositivos}
        >
          <s.ContainerModDin>
            <div>DISPOSITIVOS</div>
          </s.ContainerModDin>
        </Modal>

        <Modal
          exibir={Mdpagamentos}
          setStateExibir={setMdpagamentos}
        >
          <s.ContainerModDin2>
            <h1>PAGAMENTOS</h1>
            {
              listPagamentos.length > 0 ? (
                listPagamentos.map((pagamento, index) => {
                  return (
                    <s.CardPay key={index}>
                      <s.Contleft>
                        <p>ID</p>
                        <p>{pagamento.id}</p>
                      </s.Contleft>

                      <s.ContMid>
                        <p>Data do pagamento : {dataExtenso(new Date(pagamento.data_pagamento).toLocaleDateString(), '/')}</p>
                        <p>Valor : R$ {pagamento.valor_pago}</p>
                        <p>Status : {pagamento.status}</p>
                      </s.ContMid>

                      <s.ContRight>
                        {pagamento.status == 'pago' ?
                          <s.BallGreen></s.BallGreen>
                          :
                          <s.BallOrange></s.BallOrange>
                        }
                      </s.ContRight>

                    </s.CardPay>
                  )
                }
                )
              )
                :
                (
                  <p>Nenhum pagamento encontrado.</p>
                )
            }
          </s.ContainerModDin2>
        </Modal>

        <Modal
          exibir={MdVeiculos}
          setStateExibir={setMdVeiculos}
        >
          <div>Veiculos</div>
        </Modal>


      </s.ContainerCard>
    )
  }



  return (
    <s.ContainerGeral >

      {

        !adicionarVenda ?
          <s.SubContainerGeral>
            <s.ContaineRow cor={cor[tipo - 1]}>

              <s.BotaoProdutos
                cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => { setTipo(2) }}>
                <Fa.FaHandshake
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>CONTRATOS</p>
              </s.BotaoProdutos>

              <s.BotaoProdutos
                cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => { setAdicionarVenda(true) }}>
                <Fa.FaPlusSquare
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>NOVO</p>
              </s.BotaoProdutos>
            </s.ContaineRow>

            <s.ContainerScroll
              className='elemento'>

              {
                listaContratos.length > 0 ? (
                  <s.ContainerScroll>
                    {
                      listaContratos.map((c, index) => (
                        <CardContratos
                          valores={{
                            MdDetalhes,
                            Mdpagamentos,
                            MdVeiculos,
                            MdDispositivos,
                            listPagamentos,
                            pagamentos,
                            setMdDetalhes,
                            setMdpagamentos,
                            setMdVeiculos,
                            setMdDispositivos,
                            setListPagamentos,
                            c,
                            index
                          }}
                        />
                      ))
                    }
                  </s.ContainerScroll>
                ) : (
                  <s.DivVaziaImg>
                    <h1>Nunhum contrato cadastrado</h1>
                  </s.DivVaziaImg>
                )
              }
            </s.ContainerScroll>
          </s.SubContainerGeral>
          :
          <CompAdicionarVenda
            id='produtos'
            cor={cor[tipo - 1]}
            setAdicionarVenda={setAdicionarVenda}
            gravar={gravar}
          />
      }
    </s.ContainerGeral>

  )
}


