import { useContext } from 'react';
import { AuthContext } from '../contexts/authContext';
import AppRoutes from './app.routes.jsx';
import AuthRoutes from './auth.routes.jsx';

export default function Rotas() {

  const { signed } = useContext(AuthContext)
  
  return (
    signed ? <AppRoutes /> : <AuthRoutes />
  )

}