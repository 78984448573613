import React from "react";
import MenuAcoes from "../../components/menu_acoes/menu_acoes";
import * as s from "./estiloRelatorios";

function RakingVendas() {

  function imprimir() {
    window.print()
  }

  return (
    <s.ContainerGeral>
      <MenuAcoes
 
        imprimir={true}
        onClickImprimir={() => imprimir()}

      />

      <s.SubContainerGeral>RakingVendas</s.SubContainerGeral>
    </s.ContainerGeral>
  );
}

export default RakingVendas;
