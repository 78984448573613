import styled from "styled-components";

export const SubContainerGeral = styled.div`
    width: 100%;
    height: 100vh;
    overflow: none;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

`;
export const ContaineRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid ${c => c.cor};
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .container__addIcon  {

        min-width: 60px;
        height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 10px;
        padding: 0px 15px;

        p {
            font-size:14px ;
            font-weight: bold;
            color: #fff;
        }
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }

    @media only screen and (max-width: 750px){
        display: inline-flex;
        flex-wrap: wrap;
        height: auto;
        border-bottom: none;

        .container__addIcon  {

            min-width: 50px;
            height: 30px;
            border-radius: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 10px;
            padding: 0px 10px;

            p {
                font-size:11px ;
                font-weight: bold;
                color: #fff;
            }
        }
        .Icon  {
            color: #fff;
            width: 14px;
            height: 14px;
        }
        
    }
   
`;
export const BotaoNovo = styled.div`
    background-color: ${c => c.cor};
`;
export const BotaoProdutos = styled.div`
    background-color: ${c => c.cor};
`;
export const ContainerBotao = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 15px;
    gap:10px;
    position: relative;

    .div_options {
        z-index: 1000;
        flex: 1;
        display: flex;
        flex-direction: row;
        gap:10px;

    }
    .div_subptions {
        z-index: 2001;
        position: absolute;
        width: 300px;
        min-height: 50px;
        z-index: 2000;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:5px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        right: -300px;
    }
    
    cursor: pointer;
    
    p {
        font-weight: 500;
        color: #444746;
    }
    
    svg {
        width: 20px;
        height: 20px;
        color: #444746;
    }
    
`;
export const ContainerRowGrupo = styled.div`
   
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 0px;
    gap: 10px;
    padding-bottom: 1000px;
    /* border: 1px solid grey; */
    /* background-color: #fff; */
    
    .container__addIcon  {
        width: 33px;
        height: 33px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #469CAC;
        cursor: pointer;
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }

    @media only screen and (max-width: 600px){
        flex-direction: column;
        align-items: center;
    }
   
`;
export const ContainerScroll = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 30px;
  
`;
export const TituloGrupo = styled.h1`
    /* background-color: #fff; */
    font-size: 16px;
    margin: 25px 25px 0px 25px;
    color: #143429;

    @media only screen and (max-width: 600px){
       
    }
`;
export const TituloGrupo2 = styled.h1`
    /* background-color: #fff; */
    font-size: 16px;
    color: #143429;

    @media only screen and (max-width: 600px){
       
    }
`;
export const ContainerRowButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    div {
        background-color: #143429;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;

        svg {
            color: #fff;
        }
    }
`;
export const ContainerInputPesq = styled.div`
    width: 100%;
    text-align: center;
    font-family: Arial !important;
    display: inline-flex;
    flex-wrap: wrap; 
    background-color: #fff;
    padding: 0px 25px 35px 25px;
    border-radius: 5px;
`;
export const ContainerFildes = styled.div`
    width: 100%;
    text-align: center;
    font-family: Arial !important;
    display: inline-flex;
    flex-wrap: wrap; 
    background-color: #fff;
    border-radius: 5px;
`;
export const ContainerFildes2 = styled.div`
    width: 100%;
    text-align: center;
    /* font-family: Arial !important; */
    display: inline-flex;
    flex-wrap: wrap; 
    background-color: #fff;
    border-radius: 5px;
    padding: 25px;
`;
export const ContainerInputFi = styled.div`
    min-width: 250px;
    flex: 1;
    margin: 4px;
`;
export const ContainerModalOpcoes = styled.div`
    transition: all ease-in-out .2s;

    width:100%;
    height: 100% ;
    position: fixed;
    top: 0px;
    right: 0px;
    /* background-color: #0000009e; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: none;

    @media print{
        display: block;
        height: auto;
        margin: 0px;
        padding: 0px;
        .ocultar{
            display: none;
        }
    }

`;
export const ModalOpcoesBotoesDinamico = styled.div`
    min-width: 230px;
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 2000;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding:  10px 0px;
    position: absolute;
    top: ${p => (p.pos.y + 10)}px;
    left: ${p => p.pos.x - 230}px;
`;
export const ContainerScrollCategorias = styled.div`
   width: 100%;
    overflow-y: auto;
`;
export const ContainerFechar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    /* background-color: red; */
    gap: 15px;
    padding: 5px 15px 0px 15px;

    svg {
        width: 25px;
        height: 25px;
        color: #143429;
    }
    p {
        color: #143429;
    }

    
   
`;

