import React, { useState } from "react";
import * as s from "./styles";
import ReactModal from 'react-modal'; //https://reactcommunity.org/react-modal/

/**Modal personalizado */
export default function Modal({ children, exibir, titulo = null, setStateExibir, ...restProsp }) {


    //fechar modal
    function fechar(valor) {
        setStateExibir(valor);
    }

    return (
        <ReactModal isOpen={exibir}
            style={{ zIndex: 9999999999999999999999 }}
        >
            <s.divPai>
                <s.btnFechar onClick={() => { fechar(false) }}>X</s.btnFechar>
                {
                    titulo &&
                    <s.divTitulo><h1>{titulo}</h1></s.divTitulo>
                }
                <s.divFilho {...restProsp} >
                    {children}
                </s.divFilho>
            </s.divPai>
        </ReactModal>
    )
}