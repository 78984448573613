import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloLista";
import api from "../../../services/apiAxios";
import { AuthContext } from "../../../contexts/authContext";
import Pesquisa from "../../../components/pesquisa/pesquisa";
import * as Fa from "react-icons/fa";
import AlertaDeMensagem from "../../../components/AlertaMensagem/AlertaDeMensagem";
import semcar from "../../../img/car-icon.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Veiculos from "../cadveiculo/veiculos";


export default function ListaVeiculos({ id_cliente2 }) {
    const { id_cliente } = useParams()
    const navigate = useNavigate()
    const { Spinner } = useContext(AuthContext);
    const [listaVeiculo, setListaVeiculo] = useState([]);
    const [listaVeiculo2, setListaVeiculo2] = useState([]);
    const [addEditVeiculo, setAddEditVeiculo] = useState(null);

    let id_clienteFiltro = (id_cliente || id_cliente2)


    useEffect(() => {
        getVeiculos(id_clienteFiltro)
    }, []);

    async function getVeiculos(id_clienteFiltro) {
        Spinner(true);
        try {
            const consulta = await api.get(`/clientes/veiculoscliente/${id_clienteFiltro}`)
            if (consulta.data.status) {
                setListaVeiculo(consulta.data.resultado)
                setListaVeiculo2(consulta.data.resultado)
            } else {
                AlertaDeMensagem(
                    "alerta",
                    "Este cliente não possui veiculos ",
                    "ATENÇÃO!",
                    4000
                );
            }
        } catch (error) {
            console.log(error);
        }
        Spinner(false)
    }
    function functionCor(index) {
        const cor = ["#2e4a66", "#a98921", "#469cac", "#3b9668"];
        if (index % 4 == 0) {
            return cor[0];
        } else if (index % 4 == 1) {
            return cor[1];
        } else if (index % 4 == 2) {
            return cor[2];
        } else if (index % 4 == 3) {
            return cor[3];
        }
    }
    function filtraLista(texto) {
        let novoArray = listaVeiculo2.filter(
            (e) =>
                e.renavam.toLowerCase().includes(texto.toLowerCase()) ||
                e.marca.toLowerCase().includes(texto.toLowerCase()) ||
                e.categoria.toLowerCase().includes(texto.toLowerCase()) ||
                e.modelo.toLowerCase().includes(texto.toLowerCase()) ||
                e.ano_fabricacao.toString().includes(texto) ||
                e.ano_modelo.toString().includes(texto) ||
                e.chassi.toString().includes(texto) ||
                e.localidade.toString().includes(texto) ||
                e.id_veiculo.toString().includes(texto)
        );
        setListaVeiculo(novoArray);
    }
    function editarouAddVeiculo(obj, verbo) {
        const obj2 = {
            ...obj,
            verbo: verbo
        }
        setAddEditVeiculo(obj2)
        // navigate(`/cadveiculos/${encodeURIComponent(JSON.stringify(obj2))}`);
    }


    return (
        <>
            {
                addEditVeiculo != null ?
                    <Veiculos dados={addEditVeiculo}
                        setAddEditVeiculo={setAddEditVeiculo}
                    />
                    :

                    <s.ContainerGeral>
                        <s.ContaineRow>
                            <Pesquisa
                                placeholder="Pesquisa de Veiculos"
                                setValue={filtraLista}
                            />
                            <div id="container__addIcon">
                                <Fa.FaRegPlusSquare
                                    onClick={() => {
                                        editarouAddVeiculo(
                                            { id_cliente: id_clienteFiltro, },
                                            'insert'
                                        )
                                    }}
                                    className="fa fa-times Icon"
                                    id="Icon"
                                    aria-hidden="true"
                                />
                            </div>
                        </s.ContaineRow>

                        {listaVeiculo.map((elementos, indice) =>
                            <s.ContainerCard
                                key={`${id_clienteFiltro}${elementos.placa}`}
                                cor={functionCor(indice)}
                            >
                                <s.ContainerDados
                                    cor={functionCor(indice)} >
                                    <img
                                        src={elementos.foto || semcar}
                                        alt="veiculo"
                                    />
                                    <s.Greeting>
                                        <h1> {elementos.marca} - {elementos.modelo}</h1>
                                        <p>Placa: {elementos.placa}</p>
                                        <p> Categoria : {elementos.categoria} </p>
                                        <p> Localidade : {elementos.localidade} </p>
                                        <p>{elementos.observacao} </p>
                                    </s.Greeting>
                                </s.ContainerDados>

                                <s.ContainerBotoes
                                    onClick={() => editarouAddVeiculo(elementos, 'update')}
                                >
                                    <s.BotoesCard
                                        cor={functionCor(indice)}>
                                        <Fa.FaEdit
                                            className="fa fa-times"
                                            id="icon-conar"
                                            aria-hidden="true"
                                        />
                                    </s.BotoesCard>
                                    <s.Text cor={functionCor(indice)}>Editar</s.Text>
                                </s.ContainerBotoes>
                            </s.ContainerCard>
                        )}
                    </s.ContainerGeral>

            }


        </>
    );
}