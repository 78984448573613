import './navbar.css'
import avatar from '../../img/logo-stock2.png'
import * as Fa from "react-icons/fa";
import { useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';

const NavBar = ({ statusSidebar, setStatusSidebar, pesquisaAtiva = false, setPesquisa }) => {
    const { user } = useContext(AuthContext)

    return (

        <nav className='navbar'>
            <div className='container-menu-titulo'>
                {
                    !statusSidebar &&
                    <div className='nav_icon' onClick={() => setStatusSidebar(!statusSidebar)}>
                        <Fa.FaBars
                            onClick={() => setStatusSidebar(!statusSidebar)}
                            className='fa fa-times'
                            id='navbarIcon'
                            aria-hidden='true'
                        />
                    </div>
                }
                <h1>Kweb Stock</h1>
            </div>

            <div className='navbar__right'>
                <a href='#'>
                    <img
                        style={
                            {
                                width:'40px',
                                height:'40px',
                                objectFit:'contain',
                                borderRadius:'5px'
                            }
                        }

                        src={avatar}
                        alt='avatar'
                    />
                </a>

            </div>
        </nav>
    )
}

export default NavBar;