import { useContext, useEffect, useState } from 'react';
import * as s from './estiloCompNovaVenda'
import * as Fa from "react-icons/fa";
import InputKweb from '../../../components/InputPersonalizado/input_personalizavel';
import noimage from "../../../img/profile.png";
import { AuthContext } from '../../../contexts/authContext';
import Modal from '../../../components/Modal/ModalPers';
import ComponenteSelecionarProdutos from '../../../components/modal_produtos/compProdutos';


let arrayFora = []
let arrayForaVeiculos = []
export default function CompAdicionarVenda({ cor, setAdicionarVenda, gravar }) {
    const { setModalListaClientes, clienteSelecionado, setModalListaVeiculos, veiculosSelecionados, setVeiculosSelecionados } = useContext(AuthContext)
    const [atualiza, setAtualiza] = useState(false)
    const [arrayVeiculos, setArrayVeiculos] = useState([])
    const [tipoLocacao, setTipoLocacao] = useState('mensal')


    useEffect(() => {
        if (veiculosSelecionados != null) {
            let diferentes = arrayForaVeiculos.filter(e => e.id_veiculo != veiculosSelecionados?.id_veiculo)
            diferentes.push(veiculosSelecionados)
            console.log('diferentes :>> ', diferentes);
            arrayForaVeiculos = diferentes
            setAtualiza(!atualiza)
            setVeiculosSelecionados(null)
        }
    }, [veiculosSelecionados])


    useEffect(() => {
        setArrayVeiculos(arrayForaVeiculos);
    }, [atualiza])

    useEffect(() => {
        try {

            if (clienteSelecionado) {
                document.querySelector(`#id_cliente`).value = clienteSelecionado?.id_cliente
                document.querySelector(`#nome_selected`).value = `${clienteSelecionado?.id_cliente} - ${clienteSelecionado?.nome} ${clienteSelecionado?.sobrenome}`
            } else {
                document.querySelector(`#id_cliente`).value = ''
                document.querySelector(`#nome_selected`).value = ``
            }

        } catch (error) {
            console.log('error :>> ', error);
        }

    }, [clienteSelecionado])


    function removerVeiculo(id) {
        let veiculos = arrayForaVeiculos.filter(es => es.id_veiculo != id)
        arrayForaVeiculos = veiculos
        setArrayVeiculos(veiculos)
        setAtualiza(!atualiza)
    }


    function functionCor(index) {
        const cor = ["#2e4a66", "#a98921", "#469cac", "#3b9668"];
        if (index % 4 == 0) {
            return cor[0];
        } else if (index % 4 == 1) {
            return cor[1];
        } else if (index % 4 == 2) {
            return cor[2];
        } else if (index % 4 == 3) {
            return cor[3];
        }
    }

    function acaoTipoLocacao(value) {

        if (value == 'anual' && value != '') {
            let dataInicio = document.querySelector(`#data_inicio`).value
            let dataFim = document.querySelector(`#data_fim`)

            let [ano, mes, dia] = dataInicio.split('-')
            let dataFinal = `${Number(ano) + 1}-${mes}-${dia}`

            dataFim.value = dataFinal


            // const date1 = new Date(dataInicio);
            // const date2 = new Date(dataFinal);

            // const differenceInTime = date2.getTime() - date1.getTime();
            // const differenceInDays = differenceInTime / (1000 * 3600 * 24);

            // console.log(`Diferença em dias: ${differenceInDays}`);

        } else if (value == 'mensal' && value != '') {
            let dataFinal = document.querySelector(`#data_fim`)
            dataFinal.value = ''




        }

        setTipoLocacao(value)
    }

    return (
        <s.SubContainerGeral>
            <s.ContaineRow cor={cor}>

                <s.BotaoProdutos
                    cor={'#143429'}
                    className='container__addIcon'
                    onClick={() => { setAdicionarVenda(false) }}>
                    <Fa.FaArrowAltCircleLeft

                        className='fa fa-times Icon'
                        aria-hidden='true'
                    />
                    <p>Voltar</p>
                </s.BotaoProdutos>

                <s.BotaoProdutos
                    cor={'#143429'}
                    className='container__addIcon'
                    onClick={() => { gravar(tipoLocacao) }}>
                    <Fa.FaSave

                        className='fa fa-times Icon'
                        aria-hidden='true'
                    />
                    <p>Gravar</p>
                </s.BotaoProdutos>
            </s.ContaineRow>

            <s.ContainerRowGrupo>


                <s.ContainerScroll >


                    <s.ContainerFildes >
                        <s.TituloGrupo>{'Dados do cliente'}</s.TituloGrupo>
                        <s.ContainerInputPesq className='contrato'>

                            <s.ContainerInputFi
                                style={{ width: '100%', display: 'none' }}
                            >
                                <InputKweb
                                    titulo='id Cliente'
                                    placeholder='id Cliente'
                                    type='button'
                                    id='id_cliente'
                                    name='id_cliente'
                                    style={{ width: '100%' }}
                                    acaoBotao={() => { setModalListaClientes(true) }}
                                />
                            </s.ContainerInputFi>
                            {/* id_cliente, valor_mensal, valor_anual, data_inicio, data_fim, tipo_locacao, status  */}
                            <s.ContainerInputFi>
                                <InputKweb
                                    titulo='Cliente'
                                    placeholder='Cliente'
                                    type='button'
                                    id='nome_selected'
                                    name='nome_selected'
                                    style={{ width: '100%' }}
                                    acaoBotao={() => { setModalListaClientes(true) }}
                                />
                            </s.ContainerInputFi>

                            <s.ContainerInputFi>
                                <InputKweb
                                    titulo='Tipo de Locação'
                                    placeholder='data_inicio'
                                    type='select'
                                    id='tipo_locacao'
                                    name='tipo_locacao'
                                    style={{ width: '100%' }}
                                    onChange={(e) => acaoTipoLocacao(e.target.value)}
                                >
                                    <option selected value={'mensal'}>Mensal</option>
                                    <option value={'anual'}>Anual</option>

                                </InputKweb>
                            </s.ContainerInputFi>

                            <s.ContainerInputFi>
                                <InputKweb
                                    titulo='Início do Contrato'
                                    placeholder='data_inicio'
                                    type='date'
                                    id='data_inicio'
                                    name='data_inicio'
                                    defaultValue={new Date().toISOString().slice(0, 10)}
                                    style={{ width: '100%' }}
                                />
                            </s.ContainerInputFi>

                            <s.ContainerInputFi>
                                <InputKweb
                                    titulo='Fim do Contrato'
                                    placeholder='data_fim'
                                    type='date'
                                    id='data_fim'
                                    name='data_fim'
                                    style={{ width: '100%' }}
                                />
                            </s.ContainerInputFi>

                            {
                                tipoLocacao == 'mensal' ?
                                    <s.ContainerInputFi>
                                        <InputKweb
                                            titulo='Valor Mensal'
                                            placeholder='valor_mensal'
                                            type='number'
                                            id='valor_mensal'
                                            name='valor_mensal'
                                            style={{ width: '100%' }}
                                        />
                                    </s.ContainerInputFi>
                                    :
                                    <s.ContainerInputFi>
                                        <InputKweb
                                            titulo='Valor Anual'
                                            placeholder='valor_anual'
                                            type='number'
                                            id='valor_anual'
                                            name='valor_anual'
                                            style={{ width: '100%' }}
                                        />
                                    </s.ContainerInputFi>
                            }



                        </s.ContainerInputPesq>
                    </s.ContainerFildes>

                    {
                        clienteSelecionado && (
                            <>
                                <s.ContainerFildes2 id='ids_veiculos'>
                                    <s.ContainerRowButton>
                                        <div id='div' onClick={() => setModalListaVeiculos(true)}>
                                            <Fa.FaPlus />
                                        </div>
                                        <div id='flex'>
                                            <s.TituloGrupo2>Selecionar Veículos</s.TituloGrupo2>
                                            <h2></h2>
                                        </div>
                                    </s.ContainerRowButton>
                                    {
                                        arrayVeiculos.length > 0 &&
                                        arrayVeiculos.map((c, index) =>
                                            <s.ContainerCardGrupo
                                                cor={functionCor(index)}
                                                key={c?.id_veiculo}

                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    // border: '1px solid #000'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        alignItems: 'center',
                                                        gap: '15px'
                                                    }}
                                                >
                                                    <s.ContainerCardLimpo
                                                        cor={functionCor(index)}
                                                        key={c?.id_veiculo}
                                                    >
                                                        <img
                                                            src={c?.foto || noimage}
                                                            alt="hello"
                                                        />
                                                        <s.Greeting>
                                                            <h1>
                                                                {c?.marca} - {c?.modelo}
                                                            </h1>
                                                            <p>Placa: {c?.placa}</p>

                                                        </s.Greeting>
                                                    </s.ContainerCardLimpo>

                                                    <div id='div' onClick={() => { removerVeiculo(c?.id_veiculo) }} >
                                                        <Fa.FaTrash size={20} color={'#E91E63'} />
                                                    </div>
                                                    <input
                                                        type='hidden'
                                                        className='id_Veiculo'
                                                        id={c?.id_veiculo}
                                                        name={c?.id_veiculo}
                                                        value={JSON.stringify(c)}
                                                    />
                                                </div>

                                                <CompDispositivos c={c} index={index} id_veiculo={c?.id_veiculo} />

                                            </s.ContainerCardGrupo>
                                        )
                                    }
                                </s.ContainerFildes2>
                            </>
                        )
                    }


                </s.ContainerScroll>

            </s.ContainerRowGrupo>

        </s.SubContainerGeral>
    )


}

function CompDispositivos({id_veiculo}) {
    // console.log('id_veiculo :>> ', id_veiculo);
    const [arrayDispositivos, setArrayDispositivos] = useState([])
    const [dispositivoSelecionado, setDispositivoSelecionado] = useState(null)
    const [modalLista, setModalLista] = useState(false)

    useEffect(() => {

        if (dispositivoSelecionado != null) {
            let diferentes = arrayDispositivos.filter(e => e.id_produto != dispositivoSelecionado?.id_produto)
            diferentes.push(dispositivoSelecionado)
            setArrayDispositivos(diferentes)
            setDispositivoSelecionado(null)
        }

    }, [dispositivoSelecionado])

    function removerProduto(id) {
        let produtos = arrayFora.filter(es => es.id_produto != id)
        setArrayDispositivos(produtos)
    }

    function functionCor(index) {
        const cor = ["#2e4a66", "#a98921", "#469cac", "#3b9668"];
        if (index % 4 == 0) {
            return cor[0];
        } else if (index % 4 == 1) {
            return cor[1];
        } else if (index % 4 == 2) {
            return cor[2];
        } else if (index % 4 == 3) {
            return cor[3];
        }
    }
  
    return (
        <s.ContainerFildes2 >
            <s.ContainerRowButton>
                <div id='div' onClick={() => setModalLista(true)}>
                    <Fa.FaPlus />
                </div>
                <div id='flex'>
                    <s.TituloGrupo2>Adicionar Dipositivos</s.TituloGrupo2>
                    <h2></h2>
                </div>
            </s.ContainerRowButton>

            {
                arrayDispositivos.length > 0 &&
                arrayDispositivos.map((c, index) =>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '15px'
                        }}
                    >
                        <s.ContainerCard2
                            cor={functionCor(index)}
                            key={c?.id_produto}
                        >
                            <img
                                src={c?.foto || noimage}
                                alt="hello"
                            />
                            <s.Greeting2>
                                <h1>
                                    {c?.id_produto} - {c?.nome}
                                </h1>
                                <p>Número de série: {c?.numero_serie}</p>
                                <p>Iccid: {c?.iccid}</p>
                            </s.Greeting2>
                        </s.ContainerCard2>

                        <div id='div' onClick={() => { removerProduto(c?.id_produto) }} >
                            <Fa.FaTrash size={20} color={'#E91E63'} />
                        </div>

                        <input
                            className={`ids_produtos${id_veiculo}`}
                            type='hidden'
                            id={c?.id_produto}
                            name={c?.id_produto}
                            value={JSON.stringify(c)}
                        />
                    </div>
                )
            }

            <Modal
                exibir={modalLista}
                setStateExibir={setModalLista}
            >

                <ComponenteSelecionarProdutos
                    setDispositivoSelecionado={setDispositivoSelecionado}
                    setModalLista={setModalLista}
                />

            </Modal>

        </s.ContainerFildes2>
    )

}
