import { useEffect, useState } from 'react';
import * as s from './estilo_pesquisa'
import * as Fa from "react-icons/fa";

export default function Pesquisa({ setValue = null,placeholder,cor , funcao, ...resProps }) {
    const [valor, setValor] = useState('')

    useEffect(() => {

        if (setValue != null) {
            setValue(valor)
        }

    }, [valor])


    return (
        <s.ContainerPesquisa cor={cor} {...resProps}>
            <div 
                id='container_icone'>
                <Fa.FaSearch
                    onClick={() => { }}
                    className='fa fa-times'
                    id='navbarIcon'
                    aria-hidden='true'
                />
            </div>
            <input
                placeholder={placeholder || ''}
                value={valor}
                onChange={(t) => setValor(t.target.value)}
            />
        </s.ContainerPesquisa>
    );
}