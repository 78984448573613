import styled from "styled-components";

export const ContainerModalOpcoes = styled.div`
    transition: all ease-in-out .2s;

    width:100%;
    height: 100% ;
    position: fixed;
    top: 0px;
    right: 0px;
    /* background-color: #0000009e; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: none;

    @media print{
        display: block;
        height: auto;
        margin: 0px;
        padding: 0px;
        .ocultar{
            display: none;
        }
    }

`;

export const ModalOpcoesBotoesDinamico = styled.div`
    min-width: 230px;
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 2000;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap:5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding:  10px 0px;
    position: absolute;
    top: ${p => (p.pos.y + 100)}px;
    left: ${p => p.pos.x - 100}px;
`;

export const ContainerFechar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    /* background-color: red; */
    gap: 15px;
    padding: 10px 15px 10px 15px;

    svg {
        width: 25px;
        height: 25px;
        color: #143429;
    }
    p {
        color: #143429;
    }

    
   
`;

