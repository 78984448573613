import { useEffect, useState } from 'react';
import * as s from './estiloCompNovo'
import * as Fa from "react-icons/fa";
import InputKweb from '../../../components/InputPersonalizado/input_personalizavel';
import Resizer from 'react-image-file-resizer';
import noimage from '../../../img/logo.png'
import ExcelReader from '../../../components/ExcelReader/ExcelReader';

export default function CompAdicionarProdutos({ cor, setAdicionarProduto, gravar }) {
    const [ativo, setAtivo] = useState(1)
    const [arrayExelProdutos, setArrayExelProdutos] = useState([]);
    const [foto, setFoto] = useState(null);
    const [emMassa, setEmMassa] = useState(false);

    useEffect(() => {
        setArrayExelProdutos([])
    }, [emMassa])

    //upload imagem
    function fileChangedHandler(event) {
        var fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0], // É o arquivo da imagem que será redimensionada.
                    100, // É o maxWidth da nova imagem redimensionada.
                    100, // É o maxHeight da nova imagem redimensionada.
                    "JPEG", // É o compressFormat de a nova imagem redimensionada.
                    100, // É a qualidade da nova imagem redimensionada.
                    0, // É o grau de rotação no sentido horário a ser aplicado à imagem enviada.
                    (uri) => {  // É a função callBack do novo URI de imagem redimensionado.
                        setFoto(uri);
                    },
                    "base64",// É o tipo de saída da nova imagem redimensionada.
                    100,// É o minWidth da nova imagem redimensionada.
                    100 // É o minHeight da nova imagem redimensionada.
                );
            } catch (err) {
                console.log(err);
            }
        }
    }

    function removeArquivo() {
        setArrayExelProdutos([])
    }

    return (
        <s.SubContainerGeral
        // className='elemento'
        >
            <s.ContaineRow cor={cor}>

                <s.BotaoProdutos
                    cor={'#143429'}
                    className='container__addIcon'
                    onClick={() => { setAdicionarProduto(false) }}>
                    <Fa.FaArrowAltCircleLeft

                        className='fa fa-times Icon'
                        aria-hidden='true'
                    />
                    <p>Voltar</p>
                </s.BotaoProdutos>

                <s.BotaoProdutos
                    cor={ativo == 1 ? cor : '#A5ABBA'}
                    className='container__addIcon'
                    onClick={() => { setAtivo(1) }}>
                    <p>Dados do Produto</p>
                </s.BotaoProdutos>


                <s.BotaoProdutos
                    cor={'#143429'}
                    className='container__addIcon'
                    onClick={() => { gravar() }}>
                    <Fa.FaSave

                        className='fa fa-times Icon'
                        aria-hidden='true'
                    />
                    <p>Gravar</p>
                </s.BotaoProdutos>
            </s.ContaineRow>

            <s.ContainerBotoes >

                <s.SelectButtom ativo={!emMassa} lado={1} onClick={() => setEmMassa(false)}>
                    <h4>Adicionar Produto</h4>
                </s.SelectButtom>
                <s.SelectButtom ativo={emMassa} lado={2} onClick={() => setEmMassa(true)}>
                    <h4>Adicionar arquivo</h4>
                </s.SelectButtom>

            </s.ContainerBotoes>
            <s.ContainerRowGrupo>


                <s.ContainerScroll >

                    {
                        emMassa &&
                        <ExcelReader retorno={setArrayExelProdutos} removeArquivo={removeArquivo} />
                    }

                    {
                        !emMassa ?

                            <s.ContainerFildes key={`kl`} className={`valores_1`}  >
                                <s.TituloGrupo>Adicionar Produto</s.TituloGrupo>
                                <s.ContainerInputPesq >

                                    <input
                                        className='grupo'
                                        type="hidden"
                                        value={1}
                                    />
                                    <s.ContainerInputFi key={`nome`}>
                                        <>
                                            <s.ContainerFotoCadAl
                                                title="foto do produto"
                                                img={foto != null ? foto : noimage}
                                                onClick={() => { document.querySelector('#foto_produto').click() }}
                                            >
                                            </s.ContainerFotoCadAl>
                                            <input
                                                type="file"
                                                className="inputs"
                                                id={'foto_produto'}
                                                onChange={e => { fileChangedHandler(e) }}
                                                style={{ display: "none" }}
                                            />
                                            <input
                                                type="text"
                                                className="inputs"
                                                id={'foto'}
                                                name={'foto'}
                                                style={{ display: "none" }}
                                                value={foto}
                                            />
                                        </>


                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi key={`tipo`} className='ocultar'
                                    >
                                        <InputKweb
                                            titulo={'Tipo'}
                                            placeholder={'Tipo'}
                                            type={'text'}
                                            id={'tipo_1'}
                                            name={'tipo'}
                                            value={3}
                                            style={{ width: '100%' }}

                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi key={`id_produto`} className='ocultar'
                                    >
                                        <InputKweb
                                            titulo={'Id do produto'}
                                            placeholder={'Id do produto'}
                                            type={'text'}
                                            id={'id_produto'}
                                            name={'id_produto'}
                                            style={{ width: '100%' }}

                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi key={`nome`}
                                    >
                                        <InputKweb
                                            titulo={'*Nome'}
                                            placeholder={'Nome'}
                                            type={'text'}
                                            id={'nome_1'}
                                            name={'nome'}
                                            style={{ width: '100%' }}

                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>

                                    <s.ContainerInputFi key={`custo`}>
                                        <InputKweb
                                            titulo={'*Custo'}
                                            placeholder={'Custo'}
                                            type={'text'}
                                            id={'custo_1'}
                                            name={'custo'}
                                            style={{ width: '100%' }}

                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi key={`descricao`} >
                                        <InputKweb
                                            titulo={'Descrição'}
                                            placeholder={'Descrição'}
                                            type={'text'}
                                            id={'descricao'}
                                            name={'descricao'}
                                            style={{ width: '100%' }}
                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>

                                    <s.ContainerInputFi key={`numero_serie`} >
                                        <InputKweb
                                            titulo={'*Número de série'}
                                            placeholder={'Número de série'}
                                            type={'text'}
                                            id={'numero_serie_1'}
                                            name={'numero_serie'}
                                            style={{ width: '100%' }}
                                            disabled={arrayExelProdutos.length > 1 ? true : false}

                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi key={`operadora`} >
                                        <InputKweb
                                            titulo={'Operadora'}
                                            placeholder={'Operadora'}
                                            type={'text'}
                                            id={'operadora'}
                                            name={'operadora'}
                                            style={{ width: '100%' }}
                                            disabled={arrayExelProdutos.length > 1 ? true : false}

                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi key={`iccid`}
                                    >
                                        <InputKweb
                                            titulo={'ICCID'}
                                            placeholder={'ICCID'}
                                            type={'text'}
                                            id={'iccid'}
                                            name={'iccid'}
                                            style={{ width: '100%' }}
                                            disabled={arrayExelProdutos.length > 1 ? true : false}
                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi key={`linha_telefonica`}
                                    >
                                        <InputKweb
                                            titulo={'Linha Telefonica'}
                                            placeholder={'Linha Telefonica'}
                                            type={'text'}
                                            name={'linha_telefonica'}
                                            style={{ width: '100%' }}
                                            disabled={arrayExelProdutos.length > 1 ? true : false}
                                        >
                                        </InputKweb>


                                    </s.ContainerInputFi>

                                </s.ContainerInputPesq>
                            </s.ContainerFildes>
                            :
                            arrayExelProdutos.length > 0 &&
                            arrayExelProdutos.map((l, i) => {

                                return (
                                    <div style={{ flex: 1 }} key={`key_${i+1}`} >
                                        <ComponenteProduto l={l} i={i} arrayExelProdutos={arrayExelProdutos} />
                                    </div>
                                )
                            })
                    }
                </s.ContainerScroll>

            </s.ContainerRowGrupo>

        </s.SubContainerGeral>

    )


}

function ComponenteProduto({ l, i, arrayExelProdutos }) {
    const [foto, setFoto] = useState(null);

    //upload imagem
    function fileChangedHandler(event) {
        var fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0], // É o arquivo da imagem que será redimensionada.
                    100, // É o maxWidth da nova imagem redimensionada.
                    100, // É o maxHeight da nova imagem redimensionada.
                    "JPEG", // É o compressFormat de a nova imagem redimensionada.
                    100, // É a qualidade da nova imagem redimensionada.
                    0, // É o grau de rotação no sentido horário a ser aplicado à imagem enviada.
                    (uri) => {  // É a função callBack do novo URI de imagem redimensionado.
                        setFoto(uri);
                    },
                    "base64",// É o tipo de saída da nova imagem redimensionada.
                    100,// É o minWidth da nova imagem redimensionada.
                    100 // É o minHeight da nova imagem redimensionada.
                );
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <s.ContainerFildes key={`k_${i + 1}`} className={`valores_${l?.numero_serie}`}  >
            <input
                className='grupo'
                type="hidden"
                value={l?.numero_serie}
            />
            <s.TituloGrupo>Produto {l?.numero_serie}</s.TituloGrupo>
            <s.ContainerInputPesq key={1 + i}>


                <s.ContainerInputFi key={`nome_${i + 1}`}
                    className={`nome`}
                >
                    <>
                        <s.ContainerFotoCadAl
                            title="foto do produto"
                            img={foto != null ? foto : noimage}
                            onClick={() => { document.querySelector(`#foto_produto_${l?.numero_serie}`).click() }}
                        >
                        </s.ContainerFotoCadAl>
                        <input
                            type="file"
                            className="inputs"
                            id={`foto_produto_${l?.numero_serie}`}
                            onChange={e => { fileChangedHandler(e) }}
                            style={{ display: "none" }}
                        />
                        <input
                            type="text"
                            className="inputs"
                            name={'foto'}
                            style={{ display: "none" }}
                            value={foto}
                        />
                    </>

                </s.ContainerInputFi>
                <s.ContainerInputFi key={`tipo_${i + 1}`}
                    className={`ocultar`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'Tipo'}
                        placeholder={'Tipo'}
                        type={'text'}
                        name={'tipo'}
                        value={3}
                        style={{ width: '100%' }}

                    >
                    </InputKweb>


                </s.ContainerInputFi>
                <s.ContainerInputFi key={`id_produto_${i + 1}`}
                    className={`ocultar`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'Id do produto'}
                        placeholder={'Id do produto'}
                        type={'text'}
                        name={'id_produto'}
                        value={''}
                        style={{ width: '100%' }}

                    >
                    </InputKweb>


                </s.ContainerInputFi>
                <s.ContainerInputFi key={`nome_${i + 1}`}
                    className={`nome`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'*Nome'}
                        placeholder={'Nome'}
                        type={'text'}
                        id={`nome_${l?.numero_serie}`}
                        name={'nome'}
                        style={{ width: '100%' }}

                    >
                    </InputKweb>


                </s.ContainerInputFi>

                <s.ContainerInputFi key={`custo_${i + 1}`}
                    className={`custo`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'*Custo'}
                        placeholder={'Custo'}
                        type={'text'}
                        id={`custo_${l?.numero_serie}`}
                        name={'custo'}
                        style={{ width: '100%' }}

                    >
                    </InputKweb>


                </s.ContainerInputFi>
                <s.ContainerInputFi key={`descricao_${i + 1}`}
                    className={`descricao`} >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'Descrição'}
                        placeholder={'Descrição'}
                        type={'text'}
                        name={'descricao'}
                        style={{ width: '100%' }}
                    >
                    </InputKweb>


                </s.ContainerInputFi>

                <s.ContainerInputFi key={`${l.numero_serie}_${i + 1}`}
                    className={`${l.numero_serie}`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'*Número de série'}
                        placeholder={'Número de série'}
                        type={'text'}
                        id={`numero_serie_${l?.numero_serie}`}
                        name={'numero_serie'}
                        value={l.numero_serie}
                        style={{ width: '100%' }}
                        disabled={arrayExelProdutos.length > 1 ? true : false}

                    >
                    </InputKweb>


                </s.ContainerInputFi>
                <s.ContainerInputFi key={`${l.numero_serie}_${i + 1}`}
                    className={`${l.numero_serie}`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'Operadora'}
                        placeholder={'Operadora'}
                        type={'text'}
                        name={'operadora'}
                        value={l.operadora}
                        style={{ width: '100%' }}
                        disabled={arrayExelProdutos.length > 1 ? true : false}

                    >
                    </InputKweb>


                </s.ContainerInputFi>
                <s.ContainerInputFi key={`${l.iccid}_${i + 1}`}
                    className={`${l.iccid}`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'ICCID'}
                        placeholder={'ICCID'}
                        type={'text'}
                        name={'iccid'}
                        value={l.iccid}
                        style={{ width: '100%' }}
                        disabled={arrayExelProdutos.length > 1 ? true : false}
                    >
                    </InputKweb>


                </s.ContainerInputFi>
                <s.ContainerInputFi key={`${l.linha_telefonica}_${i + 1}`}
                    className={`${l.linha_telefonica}`}
                >
                    <InputKweb
                        className={`produto_${i + 1}`}
                        titulo={'Linha Telefonica'}
                        placeholder={'Linha Telefonica'}
                        type={'text'}
                        name={'linha_telefonica'}
                        value={l.linha_telefonica}
                        style={{ width: '100%' }}
                        disabled={arrayExelProdutos.length > 1 ? true : false}
                    >
                    </InputKweb>


                </s.ContainerInputFi>

            </s.ContainerInputPesq>
        </s.ContainerFildes>
    )

}