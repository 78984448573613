import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelReader = ({ retorno }) => {

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      montaArrayObjetos(jsonData)
    };

    reader.readAsArrayBuffer(file);
  };

  function montaArrayObjetos(array) {

    let newArray = []
    for (let i = 0; i < array.length; i++) {
      const el = array[i];
      if (i > 0) {

        let objeto = {
          operadora: el.__EMPTY,
          iccid: el.__EMPTY_1,
          numero_serie: el.__EMPTY_2,
          linha_telefonica: el[`Relação de Equipamento J16`]
        }

        newArray.push(objeto)
      }

    }
    retorno(newArray)
  }


  return (
    <div>
      <h1>Leitor de Arquivo Excel</h1>
      <input id='file' type="file" accept=".xlsx, .xls" onChange={handleFileUpload} /> <br />
    </div>
  );
};

export default ExcelReader;
