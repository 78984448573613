const meses = [
    { value: 1, label: 'Janeiro', dias: 31 },
    { value: 2, label: 'Fevereiro', dias: 28 }, // Considerando anos não bissextos
    { value: 3, label: 'Março', dias: 31 },
    { value: 4, label: 'Abril', dias: 30 },
    { value: 5, label: 'Maio', dias: 31 },
    { value: 6, label: 'Junho', dias: 30 },
    { value: 7, label: 'Julho', dias: 31 },
    { value: 8, label: 'Agosto', dias: 31 },
    { value: 9, label: 'Setembro', dias: 30 },
    { value: 10, label: 'Outubro', dias: 31 },
    { value: 11, label: 'Novembro', dias: 30 },
    { value: 12, label: 'Dezembro', dias: 31 }
];

import AlertaDeMensagem from './../components/AlertaMensagem/AlertaDeMensagem';

export async function buscarCep(cep) {

    let url = `https://viacep.com.br/ws/${cep}/json`

    fetch(url)
        .then(response => {
            // Verifica se a resposta foi bem-sucedida (código 200)
            if (response.ok) {
                // Converte a resposta para JSON e a retorna
                return response.json();
            }
            console.log('response :>> ', response);
            // Se a resposta não for bem-sucedida, lança um erro
            throw new Error('Erro ao obter os dados do CEP');
        })
        .then(data => {
            // Manipula os dados recebidos
            console.log('data :>> ', data);
            return data
            // Aqui você pode fazer o que quiser com os dados, como exibir na página, etc.
        })
        .catch(error => {
            // Captura e trata qualquer erro ocorrido durante a requisição
            AlertaDeMensagem('alerta', 'Verifique o cep digitado e tente novamente.', 'CEP INVÁLIDO', 4000)
            console.error(error);
        });




}

export function dataExtenso(data, separador) {
    if (data && separador) {
        const dt = data?.split(separador)
        let datExt = `${dt[0]} de ${meses[(Number(dt[1]) - 1)]?.label} de ${dt[2]}`
        return datExt
    } else {
        return data
    }
}