/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { createContext, useState } from 'react';
export const AuthContext = createContext();

const cli = JSON.parse(localStorage.getItem('array_clientes'))

export default function AuthProvider({ children }) {
    const [spinnerGlobal, setSpinnerGlobal] = useState(false)
    const [token, setToken] = useState(localStorage.getItem('@token') || '');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || null))
    const [empresa, setEmpresa] = useState(JSON.parse(localStorage.getItem('empresa') || null))
    const [pesquisaInputNavbar, setPesquisaInputsNavbar] = useState('')
    const [pesquisaAtiva, setPesquisaAtiva] = useState(false)
    const [clientesLocal, setClientesLocal] = useState(cli || [])
    const [modalListaClientes, setModalListaClientes] = useState(false)
    const [modalListaVeiculos, setModalListaVeiculos] = useState(false)
    const [clienteSelecionado, setClienteSelecionado] = useState(null);
    const [produtosSelecionados, setProdutosSelecionados] = useState(null);
    const [veiculosSelecionados, setVeiculosSelecionados] = useState(null);

  

    /**
     * Nova Estrutura do Carregamento Global
     * @param {boolean} valor true ou false
    */
    function Spinner(valor) {
        setSpinnerGlobal(valor)
    }

    async function deslogar() {
        localStorage.removeItem('@token')
        localStorage.removeItem('user')
        setUser(null)
        setToken('')
    }


    return (
        <AuthContext.Provider
            value={{
                signed: (!!token && !!user), setToken, deslogar, user, setUser, Spinner,
                pesquisaInputNavbar, setPesquisaInputsNavbar, pesquisaAtiva, setPesquisaAtiva,
                empresa, setEmpresa, setSpinnerGlobal, spinnerGlobal, modalListaClientes, setModalListaClientes,
                clienteSelecionado, setClienteSelecionado, modalListaVeiculos, setModalListaVeiculos,veiculosSelecionados, setVeiculosSelecionados,
                clientesLocal, setClientesLocal,
                produtosSelecionados, setProdutosSelecionados
            }}
        >
            {children}
        </AuthContext.Provider>
    )

}
