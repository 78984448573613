import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloLista";
import api from "../../../services/apiAxios";
import { AuthContext } from "../../../contexts/authContext";
import Pesquisa from "../../pesquisa/pesquisa";

import * as Fa from "react-icons/fa";
import AlertaDeMensagem from "../../AlertaMensagem/AlertaDeMensagem";
import CardEnderecos from "../cardEndereco/enderecos";


export default function ListaEnderecos({ id_cliente }) {
    const { Spinner } = useContext(AuthContext);
    const [listaEnderecos, setListaEnderecos] = useState([]);
    const [listaEnderecos2, setListaEnderecos2] = useState([]);
    const [addEditEndereco, setAddEditEndereco] = useState(null);


    useEffect(() => {
        if (addEditEndereco == null) {
            setListaEnderecos([])
            setListaEnderecos2([])
            buscarListaEnderecos(id_cliente)
        }
    }, [addEditEndereco]);

    async function buscarListaEnderecos(id_cliente) {
        Spinner(true);
        try {
            const consulta = await api.get(`/clientes/enderecocliente/${id_cliente}`)

            if (consulta.data.status) {
                setListaEnderecos(consulta.data.resultado)
                setListaEnderecos2(consulta.data.resultado)
                Spinner(false)
            } else {
                AlertaDeMensagem(
                    "alerta",
                    "Este cliente não possui nunhum endereço cadastrado ",
                    "ATENÇÃO!",
                    4000
                );
                Spinner(false)
            }
        } catch (error) {
            Spinner(false)
            console.log(error);
        }
    }
    function functionCor(index) {
        const cor = ["#2e4a66", "#a98921", "#469cac", "#3b9668"];
        if (index % 4 == 0) {
            return cor[0];
        } else if (index % 4 == 1) {
            return cor[1];
        } else if (index % 4 == 2) {
            return cor[2];
        } else if (index % 4 == 3) {
            return cor[3];
        }
    }
    function filtraLista(texto) {
        let novoArray = listaEnderecos2.filter(
            (e) =>
                e.logradouro.toLowerCase().includes(texto.toLowerCase()) ||
                e.complemento.toLowerCase().includes(texto.toLowerCase()) ||
                e.cidade.toLowerCase().includes(texto.toLowerCase()) ||
                e.bairro.toLowerCase().includes(texto.toLowerCase()) ||
                e.estado.toString().includes(texto) ||
                e.id_endereco.toString().includes(texto)
        );
        setListaEnderecos(novoArray);
    }

    async function excluirEndereco(id) {

        Spinner(true);
        try {
            const consulta = await api.delete(`/clientes/excluirendereco/${id}`)
            if (consulta.data.status) {
                buscarListaEnderecos(id_cliente)
                AlertaDeMensagem(
                    "sucesso",
                    "Endereço excluído com sucesso!",
                    "SUCESSO!",
                    4000
                );
            } else {
                AlertaDeMensagem(
                    "alerta",
                    "Não foi possível excluir esse endereço",
                    "ATENÇÃO!",
                    4000
                );
                Spinner(false)
            }
        } catch (error) {
            Spinner(false)
            console.log(error);
        }

    }



    return (
        <>
            {
                addEditEndereco == null ?
                    <s.ContainerGeral>
                        <s.ContaineRow>
                            <Pesquisa
                                placeholder="Pesquisa de Endereços"
                                setValue={filtraLista}
                            />
                            <div id="container__addIcon">
                                <Fa.FaRegPlusSquare
                                    onClick={() => {
                                        setAddEditEndereco({ id_cliente: id_cliente })
                                    }}
                                    className="fa fa-times Icon"
                                    id="Icon"
                                    aria-hidden="true"
                                />
                            </div>
                        </s.ContaineRow>
                        <s.ContainerScroll>
                            {listaEnderecos.map((elementos, indice) =>
                                <s.ContainerCard
                                    key={`${id_cliente}${elementos.placa}`}
                                    cor={functionCor(indice)}
                                >
                                    <s.ContainerDados cor={functionCor(indice)} >
                                        <s.Greeting>
                                            <h1> {indice + 1} - {elementos.logradouro}</h1>
                                            <p>Logradouro: {elementos.logradouro}, {elementos.numero}</p>
                                            <p> Complemento : {elementos.complemento} </p>
                                            <p> Bairro : {elementos.bairro} </p>
                                            <p> Cidade : {elementos.cidade} </p>
                                            <p> Estado : {elementos.estado} </p>
                                            <p> País : {elementos.pais} </p>
                                        </s.Greeting>
                                    </s.ContainerDados>

                                    <s.ContainerBotoes>
                                        <s.BotoesCard
                                            onClick={() => setAddEditEndereco(elementos)}
                                            cor={functionCor(indice)}>
                                            <Fa.FaEdit
                                                className="fa fa-times"
                                                id="icon-conar"
                                                aria-hidden="true"
                                            />
                                            <h6>Editar</h6>
                                        </s.BotoesCard>

                                        <s.BotoesCard
                                            onClick={() => excluirEndereco(elementos?.id_endereco)}
                                            cor={functionCor(indice)}>
                                            <Fa.FaTrash
                                                className="fa fa-times"
                                                id="icon-conar"
                                                aria-hidden="true"
                                            />
                                            <h6>Excluir</h6>
                                        </s.BotoesCard>
                                    </s.ContainerBotoes>

                                </s.ContainerCard>
                            )}
                        </s.ContainerScroll>
                    </s.ContainerGeral>
                    :
                    <CardEnderecos
                        dados={addEditEndereco}
                        setAddEditEndereco={setAddEditEndereco}
                        buscarListaEnderecos={buscarListaEnderecos}
                    />

            }


        </>
    );
}