import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Home from "../pages/Home/home";
import Clientes from "../pages/clientes/clientes";
import CadVeiculos from "../pages/veiculos/cadveiculo/veiculos";
import Consultas from "../pages/consultas/consultas";
import Produtos from "../pages/produtos/produtos";
import Usuarios from "../pages/usuarios/usuarios";

//RELATORIOS SOLICITADOS NA REUNIÃO 24/05
import RakingVendas from "../pages/relatorios/rakingVendas";
import EstoqueBaixo from "../pages/relatorios/estoqueBaixo";
import VendasData from "../pages/relatorios/vendasData";
import VendasMensal from "../pages/relatorios/vendasMensal";
import ClientesProduto from "../pages/relatorios/clientesProduto";
import ProdutosCategoria from "../pages/relatorios/produtosCategoria";
import Vendas from "../pages/vendas/vendas";
import Contratos from "../pages/alugueis/contratos";

//LISTAS
import ListaVeiculos from "../pages/veiculos/lista/listaVeiculos";


export default function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={'/home'} />} />
            <Route path='/home' element={<Home />} />
            <Route path='/vendas' element={<Vendas />} />
            <Route path='/contratos' element={<Contratos />} />
            <Route path='/clientes' element={<Clientes />} />
            <Route path='/cadveiculos/:objeto' element={<CadVeiculos />} />
            <Route path='/listaVeiculos/:id_cliente' element={<ListaVeiculos />} />
            <Route path='/produtos' element={<Produtos />} />
            <Route path='/consultas' element={<Consultas />} />
            <Route path='/usuarios' element={<Usuarios />} />

            {/* RELATORIOS SOLICITADOS NA REUNIÃO 24/05 */}
            <Route path='/relatorio/raking-vendas' element={<RakingVendas />} />
            <Route path='/relatorio/estoque-baixo' element={<EstoqueBaixo />} />
            <Route path='/relatorio/vendas-mensal' element={<VendasMensal />} />
            <Route path='/relatorio/vendas-data' element={<VendasData />} />
            <Route path='/relatorio/clientes-produto' element={<ClientesProduto />} />
            <Route path='/relatorio/produtos-categoria' element={<ProdutosCategoria />} />

            {/* =========================================================================== */}
            {/*CASO VENHA DO LOGIN, ENVIA PARA A HOME, CASO NÃO ENVIA PARA A PAGINA NÃO ENCONTRADA  */}
            <Route path='*' element={<Navigate to={'/'} />} />
        </Routes>
    );
}