import { createGlobalStyle } from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import Mr from "../assets/fonts/Montserrat-Regular.ttf";

export default createGlobalStyle`

@font-face {
        font-family: 'Montserrat';
        src: url(${Mr});
        font-style: normal;
        font-weight: 400;
       
    }

    :root {
        --cor-primaria: #2AB5D1;
        --cor-de-fundo-secundaria: #00C7C7; 
        --cor-letra-primaria: #fff;
        --transicao-padrao: all 0.3s ease 0s;
        --cinza-bordas: #ccc;
        --cinza-claro-bordas: #e3e3e3;
        --background-menu-secundaria: #838383;
        --alerta: #df5357;
        --bordas-padrao: 0px 0px 15px 3px var(--cinza-bordas);
        --shadow-medium: 0 2px 30px rgba(0,0,0,0.12);
        --background-laranja: orange;
        --verde: #6FEB3D;
        --amarelo: #E8EB31;
        --preto: #000;
        --background-menu: var(--cor-letra-primaria);
    }

    *{
        padding: 0;
        margin: 0;
        outline: 0;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: var(--cor-letra-primaria);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--cor-primaria);
            border-radius: 5px;
            border: 3px solid  var(--cinza-bordas);
        }
    }

    table {
        border-collapse: collapse;
    }

    .Toastify__toast {
        width: auto;
        h1 {
            font-size: 20px;
        }
            
    }

    html, body , #root, main{
        height: 100vh;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-family: 'Montserrat';

        
        

    }
    /* main {
        display: grid;
    } */
    p, h5, h4, h3, h2, h1{
        margin: 0; 
    }

    ${'' /* a, a:hover {
      text-decoration: none;
      color: #000;
      cursor: pointer;
    } */}

    li {
      list-style: none;
      cursor: pointer;
    }

    button{
        background: none;
        border: none;
        cursor: pointer;
    }
    button:focus{
       outline: none;
    }

`


