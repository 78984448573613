import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloUsuarios'
import api from './../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import Pesquisa from '../../components/pesquisa/pesquisa';
import * as Fa from "react-icons/fa";
import InputKweb from '../../components/InputPersonalizado/input_personalizavel';
import axios from 'axios';
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import noimage from '../../img/profile.png'
import sem_clientes from './img/sem-clientes.png'
import Resizer from "react-image-file-resizer";



export default function Usuarios() {
  const { user, setPesquisaAtiva, Spinner } = useContext(AuthContext)
  const [clienteSelecionado, setClienteSelecionado] = useState(null)
  const [listaClientes, setListaClientes] = useState([])
  const [listaClientes2, setListaClientes2] = useState([])
  const [adicionarCliente, setAdicionarCliente] = useState(false)
  const [imgUsuarioSalvar, setImgUsuarioSalvar] = useState(null);



  const [cep, setCep] = useState('')

  useEffect(() => {
    if (cep.replaceAll('-', '').length == 8) {
      buscarCep(cep)
    }
  }, [cep])

  //entra aqui quando é edição de cliente
  useEffect(() => {

    if (clienteSelecionado) {
      setImgUsuarioSalvar(clienteSelecionado.avatar)
      for (const key in clienteSelecionado) {
        const valor = clienteSelecionado[key];
        if (key.includes('tela_')) {
          try { document.getElementById(key).checked = valor } catch (error) { null }
        } else {
          try { document.getElementById(key).value = valor } catch (error) { null }
        }
      }
    }
  }, [clienteSelecionado])

  useEffect(() => {
    if (!adicionarCliente) {
      setClienteSelecionado(null)
      setImgUsuarioSalvar(null)
    }
  }, [adicionarCliente])

  async function buscarCep(cep) {

    let url = `https://viacep.com.br/ws/${cep}/json`

    // Função assíncrona para fazer a requisição

    try {
      // Faz a requisição GET utilizando Axios e aguarda a resposta
      const response = await axios.get(url);
      // Manipula os dados recebidos
      const dados = response.data
      if (dados) {

        if (dados.erro) {
          document.querySelector(`#logradouro`).value = ''
          document.querySelector(`#cidade`).value = ''
          document.querySelector(`#estado`).value = ''
          document.querySelector(`#bairro`).value = ''
          return AlertaDeMensagem('alerta', 'Verifique o cep digitado e tente novamente.', 'CEP INVÁLIDO', 4000)
        }

        document.querySelector(`#logradouro`).value = dados.logradouro
        document.querySelector(`#cidade`).value = dados.localidade
        document.querySelector(`#estado`).value = dados.uf
        document.querySelector(`#bairro`).value = dados.bairro

      } else {
        AlertaDeMensagem('alerta', 'Falha na consulta do endereço.', 'CEP INVÁLIDO', 4000)
      }
      // Aqui você pode fazer o que quiser com os dados, como exibir na página, etc.
    } catch (error) {
      // Captura e trata qualquer erro ocorrido durante a requisição
      AlertaDeMensagem('alerta', 'Erro ao obter os dados do CEP:', 'Erro', 4000)
      console.error('Erro ao obter os dados do CEP:', error);
    }

  }

  function editarCliente(cliente) {
    setClienteSelecionado(cliente)
    setAdicionarCliente(true)
  }

  useEffect(() => {
    setPesquisaAtiva(false)
    buscarClientes()
  }, [])

  function filtraLista(texto) {
    let novoArray = listaClientes2.filter(
      e => e.nome.toLowerCase().includes(texto.toLowerCase()) ||
        e.login.toLowerCase().includes(texto.toLowerCase()) ||
        e.email.toLowerCase().includes(texto.toLowerCase()) ||
        e.id_usuarios.toString().includes(texto)
    )
    setListaClientes(novoArray)
  }
  async function buscarClientes() {

    try {
      const res = await api.get(`/usuario/listausuario`)
      console.log('res :>> ', res);
      if (res.data.status) {
        setListaClientes(res.data.resultado)
        setListaClientes2(res.data.resultado)
      } else {
        setListaClientes([])
        setListaClientes2([])
        AlertaDeMensagem('alerta', 'Não existe nenhum cliente cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }
  async function gravar() {
    let elementos = document.querySelectorAll(`.valores [name]`)
    let fildsvalores = { avatar: imgUsuarioSalvar }

    for (const fild of elementos) {
      let valor = document.querySelector(`#${fild.id}`).value
      let check = document.querySelector(`#${fild.id}`).checked == true ? 1 : 0
      if (fild.type == 'checkbox') {
        fildsvalores = {
          ...fildsvalores,
          [fild.name]: check
        }
      } else {
        fildsvalores = {
          ...fildsvalores,
          [fild.name]: valor
        }
      }
    }

    let obrigatorio = [
      'nome',
      'login',
      'email',
      'ativo',
      'senha_hash',
    ]
    for (const key of obrigatorio) {
      if (!fildsvalores[key]) {
        AlertaDeMensagem('alerta', `é obrigatório passa o ${key}`)
        return
      }
    }

    console.log('fildsvalores :>> ', fildsvalores);
    try {
      const res = await api.post(`/usuario/inserir`, fildsvalores)
      if (res.data.status) {
        setAdicionarCliente(false)
        buscarClientes()
        AlertaDeMensagem('sucesso', 'Ação gravada com sucesso', 'Sucesso!', 4000)
      } else {
        AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }

  function functionCor(index) {
    const cor = ['#2e4a66', '#a98921', '#469cac', '#3b9668'];
    if (index % 4 == 0) {
      return cor[0];
    }
    else if (index % 4 == 1) {
      return cor[1];
    }
    else if (index % 4 == 2) {
      return cor[2];
    }
    else if (index % 4 == 3) {
      return cor[3];
    }
  }
  //upload imagem
  function fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0], // É o arquivo da imagem que será redimensionada.
          100, // É o maxWidth da nova imagem redimensionada.
          100, // É o maxHeight da nova imagem redimensionada.
          "JPEG", // É o compressFormat de a nova imagem redimensionada.
          100, // É a qualidade da nova imagem redimensionada.
          0, // É o grau de rotação no sentido horário a ser aplicado à imagem enviada.
          (uri) => {  // É a função callBack do novo URI de imagem redimensionado.
            setImgUsuarioSalvar(uri);
          },
          "base64",// É o tipo de saída da nova imagem redimensionada.
          100,// É o minWidth da nova imagem redimensionada.
          100 // É o minHeight da nova imagem redimensionada.
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  function setaNivel(nivel) {

    const arrayFinanceiro = ['tela_financeiro', 'tela_compras', 'tela_clientes', 'tela_locacao']
    const arrayOperacional = ['tela_clientes', 'tela_locacao']
    const arrayEstoquista = ['tela_produtos', 'tela_locacao']
    let elementos = document.querySelectorAll(`.valores [name]`)
    for (const fild of elementos) {
      let input = document.querySelector(`#${fild.id}`)
      if (nivel == '1') {
        input.checked = true
      }
      if (nivel == '2') {
        // financeiro = 'financeiro', 'compras', 'clientes', 'locacao'
        input.checked = (arrayFinanceiro.includes(fild.id)) ? true : false
      }
      if (nivel == '3') {
        // operacional = 'clientes', 'locacao'
        input.checked = (arrayOperacional.includes(fild.id)) ? true : false
      }
      if (nivel == '4') {
        // estoquista = 'produtos', 'locacao'
        input.checked = (arrayEstoquista.includes(fild.id)) ? true : false
      }

    }

  }

  /**
   * id_usuarios, nome, sobrenome, cpf, genero, data_nascimento, logradouro, numero, cidade, estado, cep, pais, fone, email, profissao, data_cadastro, ativo
   */

  //fildes dos inputs
  const filds = [
    {
      linha: [
        { label: 'Id', value: 'id_usuarios', tipo: 'text', select: [], clas: '', clas2: clienteSelecionado != null ? '' : 'ocultar' },
        { label: 'Nome', value: 'nome', tipo: 'text', select: [], clas: '', clas2: '' },
        {
          label: 'Nível de usuário', value: 'nivel_usuario_sistema', tipo: 'select',
          select: [
            { label: 'Admin', value: 1 },
            { label: 'Financeiro', value: 2 },
            { label: 'Operacional', value: 3 },
            { label: 'Estoquista', value: 4 },
          ],
          clas: '', clas2: ''
        },

      ],
      id: '1'
    },
    {
      linha: [
        { label: 'Ativo', value: 'ativo', tipo: 'select', select: [{ label: 'Sim', value: 1 }, { label: 'Não', value: 0 }], clas: '', clas2: '' },
        { label: 'E-mail', value: 'email', tipo: 'text', select: [], clas: '', clas2: '' },
      ],
      id: '2'
    },
    {
      linha: [
        { label: 'Login', value: 'login', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Senha', value: 'senha_hash', tipo: 'password', select: [], clas: '', clas2: '' },
      ],
      id: '3'
    },

  ]
  const filds2 = [
    {
      linha: [
        { label: 'Financeiro', value: 'tela_financeiro', tipo: 'checkbox', select: [], clas: '', clas2: '' },
      ],
      id: '1'
    },
    {
      linha: [
        { label: 'Locação', value: 'tela_locacao', tipo: 'checkbox', select: [], clas: '', clas2: '' },
        { label: 'Compras', value: 'tela_compras', tipo: 'checkbox', select: [], clas: '', clas2: '' },
      ],
      id: '2'
    },
    {
      linha: [
        { label: 'Clientes', value: 'tela_clientes', tipo: 'checkbox', select: [], clas: '', clas2: '' },
        { label: 'Produtos', value: 'tela_produtos', tipo: 'checkbox', select: [], clas: '', clas2: '' },
      ],
      id: '3'
    },
    {
      linha: [
        { label: 'Usuários', value: 'tela_usuario', tipo: 'checkbox', select: [], clas: '', clas2: '' },
        { label: 'Pagamentos e Custos', value: 'tela_pay_custos', tipo: 'checkbox', select: [], clas: '', clas2: '' },
      ],
      id: '3'
    },

  ]


  return (
    <s.ContainerGeral>
      {
        !adicionarCliente ?
          <>
            <s.ContaineRow>
              <Pesquisa
                placeholder='Pesquisa de usuário'
                setValue={filtraLista}
              />

              <div id='container__addIcon'>
                <Fa.FaUserPlus
                  onClick={() => { setAdicionarCliente(true) }}
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
              </div>

            </s.ContaineRow>
            <s.ContainerScroll>
              {
                listaClientes.length > 0 ? (
                  listaClientes.map((c, index) =>
                    <s.ContainerCard cor={functionCor(index)}
                      onClick={() =>
                        c.id_usuarios == 1 ? AlertaDeMensagem('alerta', 'Esse usuário não pode ser editado', 'Usuario do Sistema', 5000) :
                          editarCliente(c)
                      }
                      key={c.id_usuarios}>
                      <img src={c.avatar != null ? c.avatar : noimage} alt='hello' />
                      <s.Greeting >
                        <h1>{c.nome} {c.sobrenome}</h1>
                        <p>Código do usuário: {c.id_usuarios}</p>
                        <p>{['Administrador', 'Financeiro', 'Operacional', 'Estoquista'][c?.nivel_usuario_sistema - 1]}</p>
                      </s.Greeting>
                    </s.ContainerCard>
                  )
                )
                  :
                  <s.DivVaziaImg>
                    <img src={sem_clientes} />
                    <h1>Nunhum cliente cadastrado</h1>


                  </s.DivVaziaImg>

              }
            </s.ContainerScroll>
          </>
          :
          <s.SubContainerGeral>
            <s.ContaineRow>
              <div id='container__addIcon'>
                <Fa.FaArrowAltCircleLeft
                  onClick={() => { setAdicionarCliente(false) }}
                  className='fa fa-times Icon'
                  aria-hidden='true'
                />
              </div>

              <div id='container__addIcon'>
                <Fa.FaSave
                  onClick={() => { gravar() }}
                  className='fa fa-times Icon'

                  aria-hidden='true'
                />
              </div>
            </s.ContaineRow>

            <s.ContainerRowGrupo>
              <s.BotaoInsertImg onClick={() => document.querySelector('#avatar').click()}>
                <img
                  id='logo'
                  src={imgUsuarioSalvar ? imgUsuarioSalvar :
                    clienteSelecionado?.avatar != null ? clienteSelecionado?.avatar :
                      noimage}

                />
                <input
                  type="file"
                  className="inputs"
                  id="avatar"
                  onChange={e => { fileChangedHandler(e) }}
                  style={{ display: "none" }}
                />
              </s.BotaoInsertImg>

              <s.ContainerScroll >

                {
                  filds.map(l => {

                    return (
                      <s.ContainerInputPesq key={l.id} className='valores' >
                        {
                          l.linha.map((e, i) => {
                            return (
                              <s.ContainerInputFi key={`${e.value}_${i + 1}`}
                                className={`${e.clas2}`}
                              >
                                <InputKweb
                                  className={`salvarbd_ limparinput_ ${e.clas}`}
                                  titulo={e.label}
                                  type={e.tipo}
                                  id={e.value}
                                  name={e.value}
                                  style={{ width: '100%' }}
                                  onChange={(text) =>
                                    e.value == 'cep' ?
                                      setCep(text.target.value) : e.tipo == 'select' ?
                                        setaNivel(text.target.value) :
                                        {}
                                  }
                                >
                                  {
                                    e.select.map((op, index) =>
                                      <option
                                        key={op.value} value={op.value}>
                                        {op.label}
                                      </option>
                                    )
                                  }
                                </InputKweb>

                              </s.ContainerInputFi>
                            )
                          })
                        }
                      </s.ContainerInputPesq>
                    )
                  })
                }

                <s.ContChecks>
                  <s.TituloPerm>Permissão Do Usuário</s.TituloPerm>
                  {
                    filds2.map(l => {

                      return (
                        <s.ContainerInputPesq2 key={`tela_${l.id}1`} className='valores' >
                          {
                            l.linha.map((e, i) => {
                              return (
                                <s.ContainerInputFi key={`${e.value}_${i + 1}`}
                                  className={`${e.clas2}`}
                                >
                                  <InputKweb
                                    className={`salvarbd_ limparinput_ ${e.clas}`}
                                    titulo={e.label}
                                    type={e.tipo}
                                    id={e.value}
                                    name={e.value}
                                    style={{
                                      width: '100%',
                                    }}
                                    onChange={(text) => e.value == 'cep' ? setCep(text.target.value) : {}}
                                  >
                                    {
                                      e.select.map((op, index) =>
                                        <option key={op.value} value={op.value}>
                                          {op.label}
                                        </option>
                                      )
                                    }
                                  </InputKweb>
                                </s.ContainerInputFi>
                              )
                            })
                          }
                        </s.ContainerInputPesq2>
                      )
                    })
                  }
                </s.ContChecks>
              </s.ContainerScroll>
            </s.ContainerRowGrupo>
          </s.SubContainerGeral>
      }

    </s.ContainerGeral>

  )
}



