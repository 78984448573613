import React from 'react'
import MenuAcoes from '../../components/menu_acoes/menu_acoes'
import * as s from './estiloRelatorios'

function ClientesProduto() {

  function imprimir() {
    window.print()
  }

  return (
    <s.ContainerGeral>
    <MenuAcoes imprimir={true} onClickImprimir={() => imprimir()} />

    <s.SubContainerGeral>ClientesProduto</s.SubContainerGeral>
  </s.ContainerGeral>
  )
}

export default ClientesProduto