import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloProdutos'
import api from '../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import * as Fa from "react-icons/fa";
import { HiArchiveBoxArrowDown } from "react-icons/hi2";
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import MenuAcoes from '../../components/menu_acoes/menu_acoes';
import CompEditarProdutos from './CompEditarProdutos/comp_esditar_produtos';
import CompAdicionarProdutos from './CompNovoProduto/comp_novo_produto';
import ModalOpcoesDinamico from '../../components/ModalOpcoesDinamico/modalDinamico';
import noimage from '../../img/noimage.jpg';

export default function Produtos() {
  const { Spinner } = useContext(AuthContext)
  const [produtoSelecionado, setProdutoSelecionado] = useState(null)
  const [listaProdutos, setListaProdutos] = useState([])
  const [listaProdutos2, setListaProdutos2] = useState([])
  const [adicionarProduto, setAdicionarProduto] = useState(false)
  const [editarProdutos, setEditarProdutos] = useState([])
  const [tipoSelect, setTipoSelect] = useState([])
  const [tipo, setTipo] = useState(1)
  const [recarrega, setRecarrega] = useState(null)
  const [modalOpcoesEditar, setModalOpcoesEditar] = useState(false)
  const [modalOpcoesImprimir, setModalOpcoesImprimir] = useState(false)
  const [modalOpcoesDinamico, setModalOpcoesDinamico] = useState(null);

  const cor = ['#17A398', '#963484', '#064789',]


  useEffect(() => {
    if (produtoSelecionado) {
      for (const key in produtoSelecionado) {
        const valor = produtoSelecionado[key];
        try { document.getElementById(key).value = valor } catch (error) { null }
      }
    }
  }, [produtoSelecionado, recarrega])
  useEffect(() => {
    if (!adicionarProduto) {
      setProdutoSelecionado(null)
      buscarProdutos()
    }
  }, [adicionarProduto])

  function filtraLista(texto) {
    let novoArray = listaProdutos2.filter(
      e => e?.nome?.toLowerCase().includes(texto?.toLowerCase()) ||
        e?.numero_serie?.toLowerCase().includes(texto?.toLowerCase()) ||
        e?.codigo_barras?.toLowerCase().includes(texto?.toLowerCase()) ||
        e?.modelo_produto?.toLowerCase().includes(texto?.toLowerCase()) ||
        e?.descricao?.toLowerCase().includes(texto?.toLowerCase()) ||
        e?.nome_categoria?.toLowerCase().includes(texto?.toLowerCase()) ||
        e?.id_produto?.toString().includes(texto)
    )
    setListaProdutos(novoArray)
  }

  async function buscarProdutos() {
    Spinner(true)
    setListaProdutos([])
    setListaProdutos2([])

    try {
      const res = await api.get(`/produtos?tipo=${tipo}`)
      if (res.data.status) {
        setListaProdutos(res.data.resultado)
        setListaProdutos2(res.data.resultado)
        Spinner(false)
      } else {
        setListaProdutos([])
        setListaProdutos2([])
        AlertaDeMensagem('alerta', 'Não existe nenhum produto cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
        Spinner(false)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }

  async function gravar() {
    let grupos = document.querySelectorAll(`.grupo`)

    let arraysProdutos = []
    let obrigatorio = [
      'nome',
      'custo',
      'numero_serie',
    ]

    for (const e of grupos) {

      let obj = null
      let produtos = document.querySelectorAll(`.valores_${e.value} [name]`)

      for (const elem of produtos) {


        if (obrigatorio.includes(elem.name) && !elem.value) {

          //ação que vai focar no input que não foi preenchido
          try { document.querySelector(`#${elem.name}_${e.value}`).focus() } catch (error) { }

          //Mensagem de retorno ao usuário informando 
          let frase = {
            nome: 'nome do produto.',
            custo: 'custo do produto.',
            numero_serie: 'número de série do produto.',
          }
          AlertaDeMensagem('alerta', `é obrigatório adicionar o ${frase[elem.name]}`)
          return
        }


        if (elem.name != 'id_produto') {

          if (elem.name == 'custo') {
            obj = {
              ...obj,
              [elem.name]: elem.value.replaceAll(',', '.')
            }

          } else {
            obj = {
              ...obj,
              [elem.name]: elem.value
            }

          }

        }
      }
      arraysProdutos.push(obj)


    }

    Spinner(true)

    for (const key in arraysProdutos) {
      const arrayfields = [];
      const arrayvalues = [];
      const inter = [];
      const element = arraysProdutos[key];

      for (const key2 in element) {
        const element2 = element[key2];
        inter.push('?')
        arrayfields.push(key2); // separa fields
        arrayvalues.push(element2); // separa values
      }

      let body = {
        filds: arrayfields,
        valores: arrayvalues,
        inter
      }

      try {
        // a cada vez passada no array de objetos com os produtos, faz a requisição separadamente
        const res = await api.post(`/produtos/insert`, body);
        if (res.data.status) {
          AlertaDeMensagem('sucesso', res.data.mensagem, 'Sucesso!', 5000)
        } else {
          AlertaDeMensagem('alerta', res.data.mensagem, 'Não Permitido', 5000)
        }

      } catch (error) {
        Spinner(false)
        AlertaDeMensagem('aleta', 'erro', 'Não Permitido', 5000)
        console.log('error :>> ', error);
      }
    }
    Spinner(false)
    setAdicionarProduto(false)
  }

  async function gravarEdicaoProdutos() {
    Spinner(true)
    let ids = document.querySelectorAll(`#grupo_edit_produtos [id]`)

    let ArrayUpdates = []

    for (const el of ids) {
      let filds = document.querySelectorAll(`#${el.id} [name]`)
      let arrayValores = []
      let update = ''
      let where = ''
      let count = 0
      for (const el of filds) {

        if (el.name != 'id_produto' && el.name != 'estoque_atual') {

          update += (count == 1 ? `${el.name} = ?` : `, ${el.name} = ?`)
          arrayValores.push(el.value)

        } else {

          if (el.name == 'id_produto') {
            where += `WHERE id_produto = ${el.value}`
          }

        }
        count++
      }

      ArrayUpdates.push({
        arrayValores,
        update,
        where
      })
    }

    for (const produto of ArrayUpdates) {

      try {

        const resposta = await api.post(`/produtos/updatedinamico`, produto)
        if (resposta.data.status) {

        } else {
          console.log('Falhou :>> ');
          console.log('resposta.data :>> ', resposta.data);
          continue

        }

      } catch (error) {
        console.log('error :>> ', error);
        continue
      }
    }
    zerarTudo()


  }
  function functionCor(index) {
    const cor = ['#2e4a66', '#a98921', '#469cac', '#3b9668'];
    if (index % 4 == 0) {
      return cor[0];
    }
    else if (index % 4 == 1) {
      return cor[1];
    }
    else if (index % 4 == 2) {
      return cor[2];
    }
    else if (index % 4 == 3) {
      return cor[3];
    }
  }
  function marcaTodos(e) {
    let cheks = document.querySelectorAll('.selecionaveis');
    for (const el of cheks) {
      el.checked = e
    }

  }
  function capturaSelecionados(tipo) {

    setTipoSelect(tipo)

    let arrayIds = []
    let produtosS = []
    let cheks = document.querySelectorAll('.selecionaveis');
    for (const el of cheks) {
      if (el.checked) {
        let id = el.id.split('_')[1]
        produtosS.push(listaProdutos.find(l => l.id_produto == id))
        arrayIds.push(id)
      }
    }

    console.log('tipo :>> ', tipo);
    if (tipo == 'excluir') {
      excluirprodutos(arrayIds)
    } else {
      setEditarProdutos(produtosS)
    }
  }

  async function excluirprodutos(arrayIds) {
    let verificacao = prompt('Atenção: Ação de carater irreversível, para continuar digite a palavra "excluir"  ')

    //verificação de segurança
    if (!verificacao || verificacao.toLowerCase().trim() != "excluir") {
      alert("Ops! digito não compatível.")
      return;
    }
    Spinner(true)
    try {
      const del = await api.post(`/produtos/excluirprodutos`, { arrayIds })

      if (del.data.status) {
        Spinner(false)
        buscarProdutos()
        AlertaDeMensagem('suscesso', 'Ação realizada com sucesso!', 'ATEÇÃO', 3000)
      } else {
        Spinner(false)
        AlertaDeMensagem('alerta', 'Não foi possível deletar o produto', 'oPS..', 3000)

      }

    } catch (error) {
      Spinner(false)
      console.log('error :>> ', error);

    }

  }


  function posicaoElemento(modal) {

    const el = document.querySelector('.elemento');
    const rect = el.getBoundingClientRect(); // Obtém as dimensões e posição do elemento
    const offsetX = event.clientX - rect.left; // Calcula o deslocamento horizontal
    const offsetY = event.clientY - rect.top; // Calcula o deslocamento vertical

    // console.log('Localização do clique em relação ao elemento:');
    // console.log('X:', offsetX);
    // console.log('Y:', offsetY);

    setModalOpcoesDinamico({ x: offsetX, y: offsetY });
    if (modal == 'editar') {

      setModalOpcoesEditar(true)
    } else {
      setModalOpcoesImprimir(true)
    }
    // setModalOpcoesEditar(true)

  }

  function zerarTudo() {
    setEditarProdutos([])
    Spinner(false)
    buscarProdutos()
    setModalOpcoesEditar(false)
    setModalOpcoesDinamico(null)
  }

  function gerarRelatorio(rota) {

    window.open(`/relatorio/${rota}`, '_blank')
  }

  console.log('listaProdutos :>> ', listaProdutos);
  return (
    <s.ContainerGeral >

      {
        //Se for não edição de produtos em lotes
        editarProdutos.length == 0 ? (

          //LISTA DE PRODUTOS
          !adicionarProduto ? (
            <s.SubContainerGeral>

              <s.ContaineRow cor={cor[tipo - 1]}>

                <s.BotaoProdutos
                  cor={tipo == 1 ? cor[tipo - 1] : '#A5ABBA'}
                  className='container__addIcon'
                  onClick={() => { setTipo(1) }}>
                  <Fa.FaBoxes

                    className='fa fa-times Icon'
                    id='Icon'
                    aria-hidden='true'
                  />
                  <p>PRODUTOS</p>
                </s.BotaoProdutos>

              </s.ContaineRow>

              <s.ContainerScroll className='elemento'>

                <MenuAcoes

                  cor={cor[tipo - 1]}
                  novo={true}
                  onClickNovo={() => setAdicionarProduto(true)}
                  editar={true}
                  onClickEditar={() => posicaoElemento('editar')}
                  onClickExcluir={() => capturaSelecionados('excluir')}
                  excluir={true}
                  imprimir={null}
                  onClickImprimir={() => posicaoElemento('imprimir')}
                  //Pesquisa
                  pesquisa={true}
                  placeholder='Buscar produtos'
                  setValue={filtraLista}
                />


                {
                  modalOpcoesEditar &&
                  <ModalOpcoesDinamico
                    modalOpcoesDinamico={modalOpcoesDinamico}
                    fecharModal={setModalOpcoesEditar}
                  >
                    <s.ContainerScrollCategorias>
                      {

                        [
                          { label: 'Nome / P. custo / Descrição', tipo: 1 },
                          { label: 'Número de série / Iccid', tipo: 2 },
                          { label: 'Operadora / Linha Telefônica', tipo: 3 },
                          { label: 'Foto do Produto', tipo: 4 },
                        ].map((e, i) =>
                          <s.ContainerBotao2
                            key={`${e.tipo}_${i + 1}`}
                            className="hover">
                            <p onClick={() => { capturaSelecionados(e.tipo) }}>
                              {e.label}</p>
                          </s.ContainerBotao2>
                        )
                      }
                    </s.ContainerScrollCategorias>
                  </ModalOpcoesDinamico>
                }
                {
                  modalOpcoesImprimir &&
                  <ModalOpcoesDinamico
                    modalOpcoesDinamico={modalOpcoesDinamico}
                    fecharModal={setModalOpcoesImprimir}
                  >
                    <s.ContainerScrollCategorias>
                      {

                        [
                          { label: 'Raking de vendas', relatorio: 'raking-vendas' },
                          { label: 'Estoque abaixo do minimo', relatorio: 'estoque-baixo' },
                          { label: 'Vendas mensais', relatorio: 'vendas-mensal' },
                          { label: 'Vendas por data', relatorio: 'vendas-data' },
                          { label: 'Clientes por produto', relatorio: 'clientes-produto' },
                          { label: 'Produtos por categoria', relatorio: 'produtos-categoria' },
                        ].map((e, i) =>
                          <s.ContainerBotao2
                            key={`key_${i + 1}`}
                            className="hover">
                            <p onClick={() => { gerarRelatorio(e.relatorio) }}>
                              {e.label}</p>
                          </s.ContainerBotao2>
                        )
                      }
                    </s.ContainerScrollCategorias>
                  </ModalOpcoesDinamico>
                }

                <s.Tabela>
                  <thead>
                    <s.LinhasTR  >
                      <s.ColunasTH  >
                        <input type='checkbox'
                          className={`check`}
                          onChange={(e) => marcaTodos(e.target.checked)}
                        />
                      </s.ColunasTH>

                      <s.ColunasTH>
                        <s.GreetingHeader className='codigo' >
                          <h1>Foto</h1>
                        </s.GreetingHeader>
                      </s.ColunasTH>
                      <s.ColunasTH>
                        <s.GreetingHeader className='codigo' >
                          <h1>Cód</h1>
                        </s.GreetingHeader>
                      </s.ColunasTH>

                      <s.ColunasTH>
                        <s.GreetingHeader className='codigo' >
                          <h1>Número de Série</h1>
                        </s.GreetingHeader>
                      </s.ColunasTH>

                      <s.ColunasTH>
                        <s.GreetingHeader className='nome' >
                          <h1>Produto</h1>
                        </s.GreetingHeader>
                      </s.ColunasTH>

                      <s.ColunasTH>
                        <s.GreetingHeader  >
                          <h1>Situação</h1>
                        </s.GreetingHeader>
                      </s.ColunasTH>

                    </s.LinhasTR>
                  </thead>
                  <tbody>
                    {
                      listaProdutos.map((c, index) =>
                        <s.LinhasTRBody key={`key_${index + 1}`} cor={functionCor(index)} >
                          <s.ColunasTD >
                            <input type='checkbox'
                              className={`check selecionaveis`}
                              id={`produto_${c.id_produto}`}
                              name={`produto_${c.id_produto}`}
                              value={c.id_produto}
                            />
                          </s.ColunasTD>
                          <s.ColunasTD>
                            <s.Greeting className='codigo' >
                              <img src={c.foto || noimage} />
                            </s.Greeting>
                          </s.ColunasTD>
                          <s.ColunasTD>
                            <s.Greeting className='codigo' >
                              <p >{c.id_produto}</p>
                            </s.Greeting>
                          </s.ColunasTD>
                          <s.ColunasTD>
                            <s.Greeting className='codigo' >
                              <p >{c.numero_serie}</p>
                            </s.Greeting>
                          </s.ColunasTD>

                          <s.ColunasTD>
                            <s.Greeting >
                              <p className='codigo'>{c.nome}</p>
                            </s.Greeting>
                          </s.ColunasTD>

                          <s.ColunasTD>
                            <s.Greeting >
                              <p className='preco'>{c.status}</p>
                            </s.Greeting>
                          </s.ColunasTD>


                        </s.LinhasTRBody>

                      )
                    }


                  </tbody>
                </s.Tabela>


              </s.ContainerScroll>

            </s.SubContainerGeral>)
            :
            (
              //Edição de produtos
              <CompAdicionarProdutos
                // className='elemento'
                cor={cor[tipo - 1]}
                setAdicionarProduto={setAdicionarProduto}
                setRecarrega={setRecarrega}
                gravar={gravar}
                tipo={tipo}
                update={produtoSelecionado?.id_produto ? true : false}
              />)
        )
          : //Edição de produtos em lotes
          (
            <s.ContainerScroll3 id='grupo_edit_produtos'>
              <s.ContaineRow2 cor={'#143429'}>

                <s.BotaoProdutos
                  cor={'#143429'}
                  className='container__addIcon'
                  onClick={() => zerarTudo()}>
                  <Fa.FaArrowAltCircleLeft
                    className='fa fa-times Icon'
                    aria-hidden='true'
                  />
                  <p>Voltar</p>
                </s.BotaoProdutos>

                <s.BotaoProdutos
                  cor={'#143429'}
                  className='container__addIcon'
                  onClick={() => { gravarEdicaoProdutos() }}>
                  <Fa.FaSave

                    className='fa fa-times Icon'
                    aria-hidden='true'
                  />
                  <p>Gravar</p>
                </s.BotaoProdutos>

              </s.ContaineRow2>
              {

                editarProdutos.map((e, i) =>
                  <div key={`key_${i + 1}`}>
                    <CompEditarProdutos produto={e} tipo={tipoSelect} />
                  </div>
                )
              }

            </s.ContainerScroll3>
          )
      }
    </s.ContainerGeral>

  )
}
