import styled from "styled-components";

export const ContainerGeral = styled.div`
    flex: 1;
    height: 100vh;
    max-width: 100vw;
    width: 100vw;
    overflow: hidden;
    /* background-color: #fff; */
    background-color: #f3f4f6;


    @media print{
        height: auto;
        display: block;
        overflow: hidden;
        margin: 0px;
        padding: 0px;
    }
`;

export const ContainerHeader = styled.div`
    background: linear-gradient(329.71deg, #AAE8ED -0.54%, #14BECC 100%);
    color: #fff;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    @media only screen and (max-width: 1083px) {
        height: 50px;
    }
    @media print {
        display: none;
    }
`;
export const MainNavSidebar = styled.div`
    display: flex;
    flex: 1;
    
    overflow: auto;
    @media print {
        overflow: hidden;
    }
    `;
export const Main = styled.div`
    flex: 1;
    width: 100%;
    
    /* height: ${p => p.signed ? '92vh' : '100vh'}; */
    /* height: 100vh; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #000;
    
    @media print {
        height: auto;
        overflow: hidden;
    }

     .ocultar {
        display: none;
    }
`;
export const ContainerFooter = styled.div`
    height: auto;
    position: fixed;
    bottom: -2px;
    left: 0px;
    width: 100%;

`;
export const ModalEmail = styled.div`
    background: rgba(0, 0, 0, 0.1);
    z-index: 10000;
    width: 100%;
    height: 100%;
`;
export const ContainerModal = styled.div`
    background: rgba(0, 0, 0, 0.1);
    z-index: 10000;
    width: 100vw;
    height: 100vh;
`;

