import React, { useContext } from "react";
//@ts-ignore

import Lottie from 'react-lottie-player'
import * as s from "./styles";
import novoSpiner from './Spinner_sbg.json'
import { AuthContext } from "../../contexts/authContext";

/**
 * carrega um modal de load, usa o contexto de de load para isso
 * @returns JSX.Element
 */
export function SpinnerLoad() {
  const { spinnerGlobal } = useContext(AuthContext)
  return (
    <>
      {spinnerGlobal && (
        <s.LoadChamarContainer>
          <Lottie
            loop
            animationData={novoSpiner}
            play
            style={{ width: 200, height: 200 }}
          />
        </s.LoadChamarContainer>
      )}
    </>
  );
}