import * as s from './estiloModalOpcoes'
import * as Fa from "react-icons/fa";


export default function ModalOpcoesDinamico({ modalOpcoesDinamico, acaoFora = null, fecharModal, children }) {

    return (
        <s.ContainerModalOpcoes onClick={() => acaoFora != null && acaoFora()} >
            <s.ModalOpcoesBotoesDinamico pos={modalOpcoesDinamico}>
                <s.ContainerFechar>
                    <p >Fechar</p>
                    <Fa.FaWindowClose onClick={() => { fecharModal() }} />

                </s.ContainerFechar>
                {children}
            </s.ModalOpcoesBotoesDinamico>
        </s.ContainerModalOpcoes>

    );
}