import styled from "styled-components";

export const ContainerGeral = styled.div`
  flex: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f4f6;
  padding: 25px;
`;
export const SubContainerGeral = styled.div`
  flex: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const ContaineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  #container__addIcon {
    width: 33px;
    height: 33px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #469cac;
    cursor: pointer;
  }
  .Icon {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  .voltar {
    color: #a5aaad;
  }
`;
export const ContainerRowGrupo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-bottom: 1000px;
  /* background-color: red; */
  #container__addIcon {
    width: 33px;
    height: 33px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #469cac;
    cursor: pointer;
  }
  .Icon {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  .voltar {
    color: #a5aaad;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ContainerScroll = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 70vh;
  gap: 20px;
  padding-bottom: 100px;

  @media only screen and (max-width: 600px) {
    height: 55vh;
  }
`;

export const ContainerInputPesq = styled.div`
  width: 100%;
  text-align: center;
  font-family: Arial !important;
  display: inline-flex;
  flex-wrap: wrap;
`;
export const ContainerInputFi = styled.div`
  min-width: 250px;
  flex: 1;
  margin: 4px;
`;

export const BotaoInsertImg = styled.div`
  min-width: 350px;
  max-height: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #469cac;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  margin-top: 15px;
  img {
    width: 99%; /* Ajustar para ocupar toda a largura do contêiner */
  height: 99%; /* Ajustar para ocupar toda a altura do contêiner */
  border-radius: 0; /* Remover border-radius para imagem quadrada */
  object-fit: cover; /* Garantir que a imagem se ajuste ao contêiner */
  }
`;

