import styled from "styled-components";

export const ContainerGeral = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;
export const ContainerPesquisa = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #469cac;
    width: 35px;
    height: 35px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;

    svg {
      color: #fff;
    }
  }

  input {
    width: 100%;
    height: 35px;
    background-color: #fff;
    padding: 5px 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    outline: none;
    border: none;
    box-shadow: 2px 2px 2px #d9d9d9;

    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;
export const ContainerBotoes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #d9d9d9; */
  border-radius: 4px;
  cursor: pointer;
  .outros-icones-check {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-width: 35px;
    height: 35px;
    padding: 0px 15px;
    border-radius: 4px;
    gap: 4px;

    b {
      color: gray;
    }

    svg {
      color: gray;
    }
  }
  .novo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(c) => c.bg};
    min-width: 35px;
    height: 35px;
    padding: 0px 15px;
    border-radius: 4px;
    gap: 4px;

    b {
      color: #fff;
    }

    svg {
      color: #fff;
    }
  }
  .novo:hover {
    background-color: #469cac;
  }

  .outros-icones {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-width: 35px;
    height: 35px;
    padding: 0px 15px;
    border-radius: 4px;
    border: 1px solid gray;
    gap: 4px;

    b {
      color: #fff;
    }

    svg {
      color: gray;
    }
  }
  /* .outros-icones:hover{
            background-color:#469CAC ;
        
    } */
`;
export const ContainerLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
export const ContainerRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;
