import { useState } from 'react';
import InputKweb from '../../../components/InputPersonalizado/input_personalizavel';
import { produtosOrigem } from '../../../utils/arrays';
import * as s from './estilo_Comp'
import Resizer from 'react-image-file-resizer';
import noimage from '../../../img/noimage.jpg'

export default function CompEditarProdutos({ produto, tipo = 1 }) {
    const [modalOpcoesDinamico, setModalOpcoesDinamico] = useState(null);
    const [foto, setFoto] = useState(produto?.foto || null);


    //upload imagem
    function fileChangedHandler(event) {
        var fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0], // É o arquivo da imagem que será redimensionada.
                    100, // É o maxWidth da nova imagem redimensionada.
                    100, // É o maxHeight da nova imagem redimensionada.
                    "JPEG", // É o compressFormat de a nova imagem redimensionada.
                    100, // É a qualidade da nova imagem redimensionada.
                    0, // É o grau de rotação no sentido horário a ser aplicado à imagem enviada.
                    (uri) => {  // É a função callBack do novo URI de imagem redimensionado.
                        setFoto(uri);
                    },
                    "base64",// É o tipo de saída da nova imagem redimensionada.
                    100,// É o minWidth da nova imagem redimensionada.
                    100 // É o minHeight da nova imagem redimensionada.
                );
            } catch (err) {
                console.log(err);
            }
        }
    }

    console.log('produto :>> ', produto);

    return (
        <s.ContainerGeralComp id={`produto_${produto?.id_produto}`}>
            {
                tipo == 1 &&
                <s.ContainerFildes >
                    <s.TituloGrupo>{produto?.id_produto} - {produto?.nome}</s.TituloGrupo>
                    <s.ContainerRows>
                        <s.ContainerInputFi className='ocultar' >
                            <InputKweb
                                style={{ width: '100%' }}
                                type='hidden'
                                name={`id_produto`}
                                titulo='id'
                                defaultValue={produto?.id_produto}
                            />

                        </s.ContainerInputFi>
                        <s.ContainerInputFi>
                            <InputKweb
                                style={{ width: '100%' }}
                                titulo='Nome'
                                name={`nome`}
                                defaultValue={produto?.nome}
                            />
                        </s.ContainerInputFi>

                        <s.ContainerInputFi>
                            <InputKweb
                                style={{ width: '100%' }}
                                name='custo'
                                titulo='Preço de custo'
                                defaultValue={produto?.custo}
                            />
                        </s.ContainerInputFi>

                        <s.ContainerInputFi>
                            <InputKweb
                                style={{ width: '100%' }}
                                name='descricao'
                                titulo='Descrição'
                                defaultValue={produto?.descricao}
                            />
                        </s.ContainerInputFi>

                    </s.ContainerRows>

                </s.ContainerFildes>
            }
            {
                tipo == 2 &&
                <s.ContainerFildes >
                    <s.TituloGrupo>{produto?.id_produto} - {produto?.nome}</s.TituloGrupo>
                    <s.ContainerRows>
                        <s.ContainerInputFi className='ocultar' >
                            <InputKweb
                                style={{ width: '100%' }}
                                type='hidden'
                                name='id_produto'
                                titulo='id'
                                defaultValue={produto?.id_produto}
                            />
                        </s.ContainerInputFi>

                        <s.ContainerInputFi>
                            <InputKweb
                                style={{ width: '100%' }}
                                titulo='Número de série'
                                name='numero_serie'
                                defaultValue={produto?.numero_serie}
                            />
                        </s.ContainerInputFi>

                        <s.ContainerInputFi>
                            <InputKweb
                                style={{ width: '100%' }}
                                titulo='Iccid'
                                name='iccid'
                                defaultValue={produto?.iccid}
                            />
                        </s.ContainerInputFi>

                    </s.ContainerRows>
                </s.ContainerFildes>
            }
            {
                tipo == 3 &&
                <s.ContainerFildes >
                    <s.TituloGrupo>{produto?.id_produto} - {produto?.nome}</s.TituloGrupo>
                    <s.ContainerRows>


                        <div className='ocultar' >
                            <InputKweb
                                style={{ width: '100%' }}
                                type='hidden'
                                name='id_produto'

                                titulo='id'
                                defaultValue={produto?.id_produto}
                            />
                        </div>
           
                        <s.ContainerInputFi>
                            <InputKweb
                                style={{ width: '100%' }}
                                titulo='Operadora'
                                name='operadora'
                                defaultValue={produto?.operadora}
                            />
                        </s.ContainerInputFi>

                        <s.ContainerInputFi>
                            <InputKweb
                                style={{ width: '100%' }}
                                titulo='Linha Telefônica'
                                name='linha_telefonica'
                                defaultValue={produto?.linha_telefonica}
                            />
                        </s.ContainerInputFi>

                    </s.ContainerRows>
                </s.ContainerFildes>
            }
            {
                tipo == 4 &&
                <s.ContainerFildes >
                    <s.TituloGrupo>{produto?.id_produto} - {produto?.nome}</s.TituloGrupo>
                    <s.ContainerInputFi className='ocultar' >
                        <InputKweb
                            style={{ width: '100%' }}
                            type='hidden'
                            name={`id_produto`}
                            titulo='id'
                            defaultValue={produto?.id_produto}
                        />

                    </s.ContainerInputFi>
                    <s.ContainerRows>
                        <s.ContainerInputFi>
                            <s.ContainerFotoCadAl
                                title="foto do produto"
                                img={foto != null ? foto : noimage}
                                onClick={() => { document.querySelector('#fotoacao').click() }}
                            >
                            </s.ContainerFotoCadAl>
                            <input
                                type="file"
                                className="inputs"
                                id={'fotoacao'}
                                onChange={e => { fileChangedHandler(e) }}
                                style={{ display: "none" }}
                            />
                            <input
                                type="text"
                                className="inputs"
                                id={'foto'}
                                name='foto'
                                value={foto}
                                style={{ display: "none" }}
                            />


                        </s.ContainerInputFi>
                    </s.ContainerRows>
                </s.ContainerFildes>
            }

        </s.ContainerGeralComp>
    );
}