import * as s from './estilo_modal_centralizado'
import * as Fa from "react-icons/fa";
export default function ModalCentralizado({fecharModal, children }) {
    return (
        <s.ContainerModal onClick={() => { }} >
            <s.SubContainerModal >
                <s.ContainerFechar>
                    <p >Fechar</p>
                    <Fa.FaWindowClose onClick={() => {fecharModal(false)}} />
                </s.ContainerFechar>
                <s.ContainerScroll>
                    {children}
                </s.ContainerScroll>
            </s.SubContainerModal>
        </s.ContainerModal>
    );
}