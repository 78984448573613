import { useContext, useEffect, useState } from 'react';
import * as s from './estiloCompNovaVenda'
import * as Fa from "react-icons/fa";
import InputKweb from '../../../components/InputPersonalizado/input_personalizavel';
import { AuthContext } from '../../../contexts/authContext';
import ModalCentralizado from '../../../components/ModalCentralizado/modal_centralizado';

export default function CompAdicionarVenda({ cor, setAdicionarVenda, gravar }) {
    const { setModalListaClientes, clienteSelecionado } = useContext(AuthContext)
    const [modalTipo, setModalTipo] = useState(false)
    const [arrayProdutos, setArrayProdutos] = useState([])
    console.log('arrayProdutos :>> ', arrayProdutos);
    useEffect(() => {
        try {
            if (clienteSelecionado) {
                document.querySelector(`#id_cliente`).value = clienteSelecionado?.id_cliente
                document.querySelector(`#nome_selected`).value = `${clienteSelecionado?.nome} ${clienteSelecionado?.sobrenome}`
            } else {
                document.querySelector(`#id_cliente`).value = ''
                document.querySelector(`#nome_selected`).value = ``
            }
        } catch (error) {

        }
    }, [clienteSelecionado])

    function acaoTipo(tipo) {
        console.log('tipo :>> ', tipo);

        if (tipo != 0) {

            if (tipo == 'Frete') {
                let item = {
                    tipo: 0,
                    id_produto: 0,
                    nome: 'Frete',
                    valor: 50,
                    qtd: 1
                }
                setArrayProdutos([...arrayProdutos, item])
            }

            setModalTipo(false)

        }
    }



    return (
        <s.SubContainerGeral>
            <s.ContaineRow cor={cor}>

                <s.BotaoProdutos
                    cor={'#143429'}
                    className='container__addIcon'
                    onClick={() => { setAdicionarVenda(false) }}>
                    <Fa.FaArrowAltCircleLeft

                        className='fa fa-times Icon'
                        aria-hidden='true'
                    />
                    <p>Voltar</p>
                </s.BotaoProdutos>

                <s.BotaoProdutos
                    cor={'#143429'}
                    className='container__addIcon'
                    onClick={() => { gravar() }}>
                    <Fa.FaSave

                        className='fa fa-times Icon'
                        aria-hidden='true'
                    />
                    <p>Gravar</p>
                </s.BotaoProdutos>
            </s.ContaineRow>

            <s.ContainerRowGrupo>


                <s.ContainerScroll >


                    <s.ContainerFildes >
                        <s.TituloGrupo>{'Dados do cliente'}</s.TituloGrupo>
                        <s.ContainerInputPesq className='valores'>

                            <s.ContainerInputFi>
                                <InputKweb
                                    titulo='id Cliente'
                                    placeholder='id Cliente'
                                    type='button'
                                    id='id_cliente'
                                    name='id_cliente'
                                    style={{ width: '100%' }}
                                    acaoBotao={() => { setModalListaClientes(true) }}
                                />
                            </s.ContainerInputFi>

                            <s.ContainerInputFi>
                                <InputKweb
                                    titulo='Cliente'
                                    placeholder='Cliente'
                                    type='button'
                                    id='nome_selected'
                                    name='nome_selected'
                                    style={{ width: '100%' }}
                                    acaoBotao={() => { setModalListaClientes(true) }}
                                />
                            </s.ContainerInputFi>
                            <s.ContainerInputFi>
                                <InputKweb
                                    titulo='id Cliente'
                                    placeholder='id Cliente'
                                    type='date'
                                    id='data_venda'
                                    name='data_venda'
                                    defaultValue={new Date().toISOString().slice(0, 10)}
                                    style={{ width: '100%' }}
                                />
                            </s.ContainerInputFi>
                        </s.ContainerInputPesq>
                    </s.ContainerFildes>

                    <s.ContainerFildes2 >
                        <s.ContainerRowButton>
                            <s.TituloGrupo2>Carrinho</s.TituloGrupo2>
                            <div onClick={() => setModalTipo(true)} >
                                <Fa.FaPlus />
                            </div>
                        </s.ContainerRowButton>
                        {
                            arrayProdutos.map(e =>
                                <s.ContainerInputPesq className='valores'>
                                    <s.ContainerInputFi className='ocultar'>
                                        <InputKweb
                                            titulo='id_produto'
                                            type='number'
                                            id='id_produto'
                                            name='id_produto'
                                            value={e?.id_produto}
                                        />
                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi>
                                        <InputKweb
                                            titulo='Nome'
                                            placeholder='Nome'
                                            type='text'
                                            id='nome'
                                            name='nome'
                                            value={e?.nome}
                                            readOnly={true}
                                            style={{ width: '100%' }}
                                        />
                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi>
                                        <InputKweb
                                            titulo='Preço'
                                            placeholder='Preço'
                                            type='text'
                                            id='preco'
                                            name='preco'
                                            value={`R$ ${Number(e?.valor).toFixed(2)}`}
                                            readOnly={true}
                                            style={{ width: '100%' }}
                                        />
                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi>
                                        <InputKweb
                                            titulo='id Cliente'
                                            placeholder='id Cliente'
                                            type='date'
                                            id='data_venda'
                                            name='data_venda'
                                            defaultValue={new Date().toISOString().slice(0, 10)}
                                            style={{ width: '100%' }}
                                        />
                                    </s.ContainerInputFi>
                                    <s.ContainerInputFi>
                                        <InputKweb
                                            titulo='id Cliente'
                                            placeholder='id Cliente'
                                            type='date'
                                            id='data_venda'
                                            name='data_venda'
                                            defaultValue={new Date().toISOString().slice(0, 10)}
                                            style={{ width: '100%' }}
                                        />
                                    </s.ContainerInputFi>
                                </s.ContainerInputPesq>
                            )
                        }


                    </s.ContainerFildes2>
                </s.ContainerScroll>

            </s.ContainerRowGrupo>

            {
                modalTipo &&
                <ModalCentralizado fecharModal={setModalTipo} >
                    <InputKweb
                        titulo='Tipo'
                        type='select'
                        id='tipo'
                        name='tipo'
                        style={{ width: '100%' }}
                        onChange={(e) => acaoTipo(e.target.value)}
                    >
                        <option value={0}>Selecione o tipo</option>
                        {
                            ['Produto', 'Frete', 'Acréscimo']
                                .map(e =>
                                    <option key={e} value={e}>{e}</option>
                                )
                        }

                    </InputKweb>

                </ModalCentralizado>
            }
        </s.SubContainerGeral>
    )


}