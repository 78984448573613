export const cor1 = '#9D5FDA';
export const cor2 = '#43BBDF';
export const cor3 = '#4B36E7';
export const cor4 = '#6A58DC';
export const cor5 = '#187216';
export const cor6 = '#49B447';
export const cor7 = '#F87A26';
export const cor8 = '#E83F5B';
export const cor9 = '#600BE3';
export const cor10 = '#4406A1';

export const cor11 = '#2AA9E0';
export const cor12 = '#600BE3';
export const cor13 = '#4406a1';
export const cor14 = '#7c29cebf';
export const cor15 = '#6220a5e3';
export const cor16 = '#2911e2d7';
export const cor17 = '#4e39d6d7';
export const cor18 = '#43bbdf';
export const cor19 = '#156114';
export const cor20 = '#47b345fc';
export const cor21 = '#f87a26';
export const cor22 = '#ff1d1de0';
export const cor23 = '#187216';
export const cor24 = '#770909';
export const vermelho = '#AC0000';
export const vermelho2 = '#FF6666';

export const destaque1 = '#FFC727';
export const destaque2 = '#347393';
export const destaque3 = '#6DE4A4';
export const destaque4 = '#FFC727';
export const destaque5 = '#143429';

export const fundo1 = '#ECECEC';
export const fundo2 = '#F1EFF0';
export const fundo3 = '#363539';

