import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloClientes";
import * as Fa from "react-icons/fa";
import { TbArrowAutofitLeft, TbArrowBack, TbArrowLeft, TbRestore } from "react-icons/tb";
import api from "../../services/apiAxios";
import { AuthContext } from "../../contexts/authContext";
import Pesquisa from "../pesquisa/pesquisa";
import AlertaDeMensagem from "../AlertaMensagem/AlertaDeMensagem";
import noimage from "../../img/profile.png";
import sem_clientes from "./img/sem-clientes.png";

export default function ComponenteSelecionarClientes() {
  const { setPesquisaAtiva, setClienteSelecionado, setModalListaClientes, clientesLocal, setClientesLocal,Spinner } = useContext(AuthContext);
  const [listaClientes, setListaClientes] = useState([]);
  const [listaClientes2, setListaClientes2] = useState([]);


  useEffect(() => {
    setPesquisaAtiva(false);
    if (clientesLocal.length == 0) {
      buscarClientes();
    } else {
      setListaClientes(clientesLocal)
      setListaClientes2(clientesLocal)
    }

  }, []);

  function filtraLista(texto) {
    let novoArray = listaClientes2.filter(
      (e) =>
        e.nome.toLowerCase().includes(texto.toLowerCase()) ||
        e.sobrenome.toLowerCase().includes(texto.toLowerCase()) ||
        e.id_cliente.toString().includes(texto)
    );
    setListaClientes(novoArray);
  }
  async function buscarClientes() {
    try {
      Spinner(true)
      const res = await api.get(`/clientes`);
      if (res.data.status) {
        setListaClientes(res.data.resultado);
        setListaClientes2(res.data.resultado);

        localStorage.getItem('array_clientes', JSON.stringify(res.data.resultado))
        setClientesLocal(res.data.resultado)
        Spinner(false)
      } else {
        setListaClientes([]);
        setListaClientes2([]);
        localStorage.removeItem('array_clientes')
        setClientesLocal([])
        Spinner(false)
        AlertaDeMensagem(
          "alerta",
          "Não existe nenhum cliente cadastrado, faça seu primeiro cadastro",
          "ATENÇÃO",
          4000
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      Spinner(false)
      AlertaDeMensagem(
        "alerta",
        "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
        "Opps...",
        4000
      );
    }
  }
  function functionCor(index) {
    const cor = ["#2e4a66", "#a98921", "#469cac", "#3b9668"];
    if (index % 4 == 0) {
      return cor[0];
    } else if (index % 4 == 1) {
      return cor[1];
    } else if (index % 4 == 2) {
      return cor[2];
    } else if (index % 4 == 3) {
      return cor[3];
    }
  }



  return (
    <s.ContainerGeral>
      <s.ContaineRow>
        <div id="container__addIcon">
          <TbArrowLeft
            onClick={() => setModalListaClientes(false)}
            className="fa fa-times Icon"
            id="Icon"
            aria-hidden="true"
          />
        </div>
        <Pesquisa
          placeholder="Pesquisa de cliente"
          setValue={filtraLista}
        />
        <div id="container__addIcon">
          <TbRestore
            onClick={() => buscarClientes()}
            className="fa fa-times Icon"
            id="Icon"
            aria-hidden="true"
          />
        </div>
      </s.ContaineRow>
      <s.ContainerScroll>
        {listaClientes.length > 0 ? (
          listaClientes.map((c, index) => (
            <>
              <s.ContainerCard
                cor={functionCor(index)}
                onClick={() => { setClienteSelecionado(c); setModalListaClientes(false) }}
                key={c.id_cliente}
              >
                <img
                  src={c.foto_cliente != null ? c.foto_cliente : noimage}
                  alt="hello"
                />
                <s.Greeting>
                  <h1>
                    {c.nome} {c.sobrenome}
                  </h1>
                  <p>Código do cliente: {c.id_cliente}</p>
                  <p>{c.profissao}</p>
                </s.Greeting>
              </s.ContainerCard>
            </>
          ))
        ) : (
          <s.DivVaziaImg>
            <img src={sem_clientes} />
            <h1>Nunhum cliente cadastrado</h1>
          </s.DivVaziaImg>
        )}
      </s.ContainerScroll>
    </s.ContainerGeral>
  );
}
