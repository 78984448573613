import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloveiculos";
import api from "../../../services/apiAxios";
import { AuthContext } from "../../../contexts/authContext";
import * as Fa from "react-icons/fa";
import InputKweb from "../../../components/InputPersonalizado/input_personalizavel";
import AlertaDeMensagem from "../../../components/AlertaMensagem/AlertaDeMensagem";
import semcar from "../../../img/car-icon.jpg";
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from "react-router-dom";


//fildes dos inputs
const filds = [
  {
    linha: [
      {
        label: "Marca",
        value: "marca",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Placa",
        value: "placa",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Modelo",
        value: "modelo",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Ano de Fabricação",
        value: "ano_fabricacao",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
    ],
    id: "1",
  },
  {
    linha: [
      {
        label: "Ano Modelo",
        value: "ano_modelo",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Chassi",
        value: "chassi",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Cor",
        value: "cor",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },

    ],
    id: "2",
  },
  {
    linha: [
      {
        label: "Renavam",
        value: "renavam",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Tipo",
        value: "tipo",
        tipo: "select",
        select: [
          { label: "Bicicleta", value: 0 },
          { label: "Ciclomotor", value: 1 },
          { label: "Motoneta", value: 2 },
          { label: "Motocicleta", value: 3 },
          { label: "Triciclo", value: 4 },
          { label: "Quadriciclo", value: 5 },
          { label: "Automóvel", value: 6 },
          { label: "Microônibus", value: 7 },
          { label: "Ônibus", value: 8 },
          { label: "Bonde", value: 9 },
          { label: "Reboque ou semi-reboque", value: 10 },
          { label: "Charrete", value: 11 },
          { label: "Carroça", value: 12 },
          { label: "Caminhonete", value: 13 },
        ],
        clas: "",
        clas2: "",
      },
      {
        label: "Categoria",
        value: "categoria",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
    ],
    id: "3",
  },
  {
    linha: [
      {
        label: "Localidade",
        value: "localidade",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Estado",
        value: "estado",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Observação",
        value: "observacao",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },

    ],
    id: "4",
  },
];


export default function Veiculos({ dados, setAddEditVeiculo }) {

  // const navigate = useNavigate()
  const { Spinner } = useContext(AuthContext);
  const [imgUsuarioSalvar, setImgUsuarioSalvar] = useState(dados.foto || null);


  async function gravar() {
    Spinner(true)
    let obj = {}

    let elementos = document.querySelectorAll(`.valores [id]`);
    for (const iterator of elementos) {
      obj[iterator.id] = iterator.value
    }
    obj = {
      ...obj,
      id_veiculo: dados.verbo == 'update' ? dados.id_veiculo : 0,
      id_cliente: dados.id_cliente,
      foto: imgUsuarioSalvar
    }

    try {
      const res = await api.post(`/clientes/insertupdateveiculo`, obj);
      if (res.data.status) {
        AlertaDeMensagem(
          "sucesso",
          "Ação gravada com sucesso",
          "Sucesso!",
          4000
        );
        // navigate(`/listaVeiculos/${obj.id_cliente}`)
        setAddEditVeiculo(null)
      } else {
        AlertaDeMensagem(
          "alerta",
          `${res.data.mensagem}`,
          "ATENÇÃO!",
          4000
        );
      }

    } catch (error) {
      console.log("error :>> ", error);
      AlertaDeMensagem(
        "alerta",
        "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
        "Opps...",
        4000
      );
    }

    Spinner(false);
  }


  //upload imagem
  function fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0], // É o arquivo da imagem que será redimensionada.
          150, // É o maxWidth da nova imagem redimensionada.
          150, // É o maxHeight da nova imagem redimensionada.
          "JPEG", // É o compressFormat de a nova imagem redimensionada.
          150, // É a qualidade da nova imagem redimensionada.
          0, // É o grau de rotação no sentido horário a ser aplicado à imagem enviada.
          (uri) => {
            // É a função callBack do novo URI de imagem redimensionado.
            setImgUsuarioSalvar(uri);
          },
          "base64", // É o tipo de saída da nova imagem redimensionada.
          150, // É o minWidth da nova imagem redimensionada.
          150 // É o minHeight da nova imagem redimensionada.
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <s.ContainerGeral>

      <s.SubContainerGeral>
        <s.ContaineRow>
          <div id="container__addIcon">
            <Fa.FaArrowAltCircleLeft
              onClick={() => { setAddEditVeiculo(null)}}
              className="fa fa-times Icon"
              aria-hidden="true"
            />
          </div>

          <div id="container__addIcon">
            <Fa.FaSave
              onClick={() => {
                gravar();
              }}
              className="fa fa-times Icon"
              aria-hidden="true"
            />
          </div>
        </s.ContaineRow>

        <s.ContainerRowGrupo>
          <s.BotaoInsertImg
            onClick={() => document.querySelector("#foto_cliente").click()}
          >
            <img
              id="logo"
              src={
                imgUsuarioSalvar
                  ? imgUsuarioSalvar
                  : semcar
              }
            />
            <input
              type="file"
              className="inputs"
              id="foto_cliente"
              onChange={(e) => {
                fileChangedHandler(e);
              }}
              style={{ display: "none" }}
            />
          </s.BotaoInsertImg>

          <s.ContainerScroll>
            {filds.map((l) => {
              return (
                <s.ContainerInputPesq key={l.id} className="valores">
                  {l.linha.map((e, i) => {
                    const valor = dados[e.value] !== undefined ? dados[e.value] : '';
                    return (
                      <s.ContainerInputFi
                        key={`${e.value}_${i + 1}`}
                        className={`${e.clas2}`}
                      >
                        <InputKweb
                          className={`salvarbd_ limparinput_ ${e.clas}`}
                          titulo={e.label}
                          type={e.tipo}
                          id={e.value}
                          name={e.value}
                          style={{ width: "100%" }}
                          defaultValue={valor}
                        >
                          {e.select.map((op, index) => (
                            <option key={op.value} value={op.value}>
                              {op.label}
                            </option>
                          ))}
                        </InputKweb>
                      </s.ContainerInputFi>
                    );
                  })}
                </s.ContainerInputPesq>
              );
            })}
          </s.ContainerScroll>
        </s.ContainerRowGrupo>
      </s.SubContainerGeral>

    </s.ContainerGeral>
  );
}
