import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloVendas'
import api from '../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import * as Fa from "react-icons/fa";
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import MenuAcoes from '../../components/menu_acoes/menu_acoes';
import ModalOpcoesDinamico from '../../components/ModalOpcoesDinamico/modalDinamico';
import CompAdicionarVenda from './CompNovaVenda/comp_nova_venda';

export default function Vendas() {
  const { setPesquisaAtiva, Spinner, setClienteSelecionado } = useContext(AuthContext)
  const [vendaSelecionada, setVendaSelecionada] = useState(null)
  const [listaVendas, setListaVendas] = useState([])
  const [listaVendas2, setListaVendas2] = useState([])
  const [adicionarVenda, setAdicionarVenda] = useState(false)
  const [tipoSelect, setTipoSelect] = useState([])
  const [tipo, setTipo] = useState(1)
  const [recarrega, setRecarrega] = useState(null)
  const [modalOpcoesImprimir, setModalOpcoesImprimir] = useState(false)
  const [modalOpcoesDinamico, setModalOpcoesDinamico] = useState(null);


  const cor = ['#17A398', '#064789', '#C32C56']

  // #FED315 amarelo
  // #1C74BC azul
  // #81DED0 verde
  // #FA667E vermelho
  //entra aqui quando é edição de produto
  useEffect(() => {
    if (vendaSelecionada) {
      for (const key in vendaSelecionada) {
        const valor = vendaSelecionada[key];
        try { document.getElementById(key).value = valor } catch (error) { null }
      }
    }

    return () => { setClienteSelecionado(null) }
  }, [vendaSelecionada, recarrega])

  useEffect(() => {
    if (!adicionarVenda) {
      setVendaSelecionada(null)
      buscarVendas()
    }

  }, [adicionarVenda])

  // function editarProduto(produto) {
  //   setVendaSelecionada(produto)
  //   setAdicionarVenda(true)
  // }

  useEffect(() => {
    setPesquisaAtiva(false)
    buscarVendas()
  }, [tipo])


  async function buscarVendas() {
    Spinner(true)
    setListaVendas([])
    setListaVendas2([])

    try {
      const res = await api.get(`/vendas`)
      if (res.data.status) {
        setListaVendas(res.data.resultado)
        setListaVendas2(res.data.resultado)
        Spinner(false)
      } else {
        setListaVendas([])
        setListaVendas2([])
        AlertaDeMensagem('alerta', 'Não existe nenhum produto cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
        Spinner(false)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }

  async function gravar() {

    let elementos = document.querySelectorAll(`.valores [name]`)
    let fildsvalores = { tipo }
    let filds = ['tipo']
    let inter = ['?']
    let valores = [tipo]
    let estoque_atual = 0

    if (tipo == 1) {
      fildsvalores = { ...fildsvalores }
      inter.push('?')

    }

    for (const fild of elementos) {
      let valor = document.querySelector(`#${fild.id}`).value

      //tratamentos de valores
      if ((fild.name == 'preco' || fild.name == 'custo') && valor) {
        valor = Number((valor.replaceAll('.', '')).replaceAll(',', '.'))
      }

      fildsvalores = {
        ...fildsvalores,
        [fild.name]: valor
      }

      if (fild.name != 'id_produto' && fild.name != 'margem' && fild.name != 'estoque_atual') {
        if (fild.name == 'id_categoria') {

          inter.push('?')
          valores.push(valor.split('-')[0].trim())
          filds.push(fild.name)

        } else {

          inter.push('?')
          valores.push(valor)
          filds.push(fild.name)

        }
      } else if (fild.name == 'estoque_atual') {
        estoque_atual = valor

      }
    }

    let obrigatorio = [
      'nome',
      'preco',
      'custo',
      'estoque_minimo',
      'estoque_atual',
    ]

    let dados = {
      estoque_atual,
      id_produto: vendaSelecionada?.id_produto || null,
      inter,
      valores,
      filds

    }

    if (tipo == 1 && recarrega == 1) {
      for (const key of obrigatorio) {
        if (!fildsvalores[key]) {
          AlertaDeMensagem('alerta', `é obrigatório passa o ${key}`)
          return
        }
      }
    }
    Spinner(true)

    try {

      const res = await api.post(`/produtos/insertupdatedinamico`, dados)

      if (res.data.status) {
        setVendaSelecionada(res.data.resultado)
        AlertaDeMensagem('sucesso', 'Ação gravada com sucesso', 'Sucesso!', 4000)
        Spinner(false)
      } else {
        AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
        Spinner(true)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
      Spinner(true)
    }
  }

  async function gravarEdicaoProdutos() {
    Spinner(true)
    let ids = document.querySelectorAll(`#grupo_edit_produtos [id]`)

    let ArrayUpdates = []

    for (const el of ids) {
      let filds = document.querySelectorAll(`#${el.id} [name]`)
      let arrayValores = []
      let update = ''
      let where = ''
      let update_estoque = ''
      let count = 0
      for (const el of filds) {
        if (el.name != 'id_produto' && el.name != 'estoque_atual') {


          update += (count == 1 ? `${el.name} = ?` : `, ${el.name} = ?`)
          if (el.name == 'id_categoria') {
            arrayValores.push(el.value.split('-')[0].trim())
          } else {
            arrayValores.push(el.value)
          }

        } else {

          if (el.name == 'id_produto') {
            where += `WHERE id_produto = ${el.value}`

          } else if (el.name == 'estoque_atual') {
            update_estoque = `UPDATE estoque SET quantidade = ${el.value}`
          }
        }
        count++
      }

      ArrayUpdates.push({
        update_estoque,
        arrayValores,
        update,
        where
      })
    }

    for (const produto of ArrayUpdates) {

      try {

        const resposta = await api.post(`/produtos/updatedinamico`, produto)
        if (resposta.data.status) {

        } else {
          console.log('Falhou :>> ');
          continue

        }

      } catch (error) {
        console.log('error :>> ', error);
        continue
      }
    }
    zerarTudo()


  }
  function functionCor(index) {
    const cor = ['#2e4a66', '#a98921', '#469cac', '#3b9668'];
    if (index % 4 == 0) {
      return cor[0];
    }
    else if (index % 4 == 1) {
      return cor[1];
    }
    else if (index % 4 == 2) {
      return cor[2];
    }
    else if (index % 4 == 3) {
      return cor[3];
    }
  }


  function marcaTodos(e) {
    let cheks = document.querySelectorAll('.selecionaveis');
    for (const el of cheks) {
      el.checked = e
    }

  }
  function capturaSelecionados(tipo) {

    setTipoSelect(tipo)

    let arrayIds = []
    let produtosS = []
    let cheks = document.querySelectorAll('.selecionaveis');
    for (const el of cheks) {
      if (el.checked) {
        let id = el.id.split('_')[1]
        produtosS.push(listaVendas.find(l => l.id_produto == id))
        arrayIds.push(id)
      }
    }
    if (tipo == 'excluir') {
      excluirprodutos(arrayIds)
    }
  }

  async function excluirprodutos(arrayIds) {
    let verificacao = prompt('Atenção: Ação de carater irreversível, para continuar digite a palavra "excluir"  ')

    //verificação de segurança
    if (!verificacao || verificacao.toLowerCase().trim() != "excluir") {
      alert("Ops! digito não compatível.")
      return;
    }
    Spinner(true)
    try {
      const del = await api.post(`/produtos/excluirprodutos`, { arrayIds })
      console.log('del :>> ', del);
      if (del.data.status) {
        Spinner(false)
        buscarVendas()
        AlertaDeMensagem('suscesso', 'Ação realizada com sucesso!', 'ATEÇÃO', 3000)
      } else {
        Spinner(false)
        AlertaDeMensagem('alerta', 'Não foi possível deletar o produto', 'oPS..', 3000)

      }
    } catch (error) {
      Spinner(false)
      console.log('error :>> ', error);

    }
  }


  function posicaoElemento(modal) {

    const el = document.querySelector('.elemento');
    const rect = el.getBoundingClientRect(); // Obtém as dimensões e posição do elemento
    const offsetX = event.clientX - rect.left; // Calcula o deslocamento horizontal
    const offsetY = event.clientY - rect.top; // Calcula o deslocamento vertical

    // console.log('Localização do clique em relação ao elemento:');
    // console.log('X:', offsetX);
    // console.log('Y:', offsetY);

    setModalOpcoesDinamico({ x: offsetX, y: offsetY });
    setModalOpcoesImprimir(true)

    // setModalOpcoesEditar(true)

  }

  function zerarTudo() {
    Spinner(false)
    buscarVendas()
    setModalOpcoesDinamico(null)
  }

  function gerarRelatorio(rota) {

    window.open(`/relatorio/${rota}`, '_blank')
  }

  return (
    <s.ContainerGeral >

      {
        //Se for não edição de produtos em lotes

        //LISTA DE Vendas
        !adicionarVenda ?
          <s.SubContainerGeral>
            <s.ContaineRow cor={cor[tipo - 1]}>

              <s.BotaoProdutos
                cor={tipo == 1 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => { setTipo(1) }}>
                <Fa.FaShoppingCart

                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>VENDAS</p>
              </s.BotaoProdutos>

              <s.BotaoProdutos
                cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => { setTipo(2) }}>
                <Fa.FaHandshake
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>ALUGUEL</p>
              </s.BotaoProdutos>

              <s.BotaoProdutos
                cor={tipo == 3 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => { setTipo(3) }}>
                <Fa.FaCartArrowDown
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>DEVOLUÇÕES</p>
              </s.BotaoProdutos>

            </s.ContaineRow>

            <s.ContainerScroll className='elemento'>

              <MenuAcoes
                cor={cor[tipo - 1]}
                novo={true}
                onClickNovo={() => setAdicionarVenda(true)}
                onClickExcluir={() => capturaSelecionados('excluir')}
                excluir={true}
                imprimir={true}
                onClickImprimir={() => posicaoElemento('imprimir')}
              />

              {
                modalOpcoesImprimir &&
                <ModalOpcoesDinamico
                  modalOpcoesDinamico={modalOpcoesDinamico}
                  fecharModal={setModalOpcoesImprimir}
                >
                  <s.ContainerScrollCategorias>
                    {

                      [
                        { label: 'Raking de vendas', relatorio: 'raking-vendas' },
                        { label: 'Estoque abaixo do minimo', relatorio: 'estoque-baixo' },
                        { label: 'Vendas mensais', relatorio: 'vendas-mensal' },
                        { label: 'Vendas por data', relatorio: 'vendas-data' },
                        { label: 'Clientes por produto', relatorio: 'clientes-produto' },
                        { label: 'Vendas por categoria', relatorio: 'produtos-categoria' },
                      ].map(e =>
                        <s.ContainerBotao2
                          className="hover">
                          <p onClick={() => { gerarRelatorio(e.relatorio) }}>
                            {e.label}</p>
                        </s.ContainerBotao2>
                      )
                    }
                  </s.ContainerScrollCategorias>
                </ModalOpcoesDinamico>
              }

              <s.Tabela>
                <thead>
                  <s.LinhasTR  >
                    <s.ColunasTH  >
                      <input type='checkbox'
                        className={`check`}
                        onChange={(e) => marcaTodos(e.target.checked)}
                      />
                    </s.ColunasTH>

                    <s.ColunasTH>
                      <s.GreetingHeader className='codigo' >
                        <h1>Cód</h1>
                      </s.GreetingHeader>
                    </s.ColunasTH>

                    <s.ColunasTH>
                      <s.GreetingHeader className='nome' >
                        <h1>Cliente</h1>
                      </s.GreetingHeader>
                    </s.ColunasTH>

                    <s.ColunasTH>
                      <s.GreetingHeader className='estoque' >
                        <h1>Preço Venda</h1>
                      </s.GreetingHeader>
                    </s.ColunasTH>

                    <s.ColunasTH>
                      <s.GreetingHeader  >
                        <h1>Data</h1>
                      </s.GreetingHeader>
                    </s.ColunasTH>

                  </s.LinhasTR>
                </thead>
                <tbody>
                  {
                    listaVendas.map((v, index) =>
                      <s.LinhasTRBody cor={functionCor(index)} >
                        <s.ColunasTD >
                          <input type='checkbox'
                            className={`check selecionaveis`}
                            id={`produto_${v?.id_registro_venda}`}
                            name={`produto_${v?.id_registro_venda}`}
                            value={v?.id_registro_venda}
                          />
                        </s.ColunasTD>
                        <s.ColunasTD>
                          <s.Greeting className='codigo' >
                            <p >{v?.id_registro_venda}</p>
                          </s.Greeting>
                        </s.ColunasTD>

                        <s.ColunasTD>
                          <s.Greeting >
                            <p className='preco'>{v?.nome}  {v?.sobrenome}</p>
                          </s.Greeting>
                        </s.ColunasTD>

                        <s.ColunasTD>
                          <s.Greeting >
                            <p className='preco'>R$ {v?.preco_total}</p>
                          </s.Greeting>
                        </s.ColunasTD>

                        <s.ColunasTD>
                          <s.Greeting >
                            <p className='preco'>{v?.data_venda_br}</p>
                          </s.Greeting>
                        </s.ColunasTD>

                      </s.LinhasTRBody>

                    )
                  }
                </tbody>
              </s.Tabela>
            </s.ContainerScroll>
          </s.SubContainerGeral>
          :
          <CompAdicionarVenda
            cor={cor[tipo - 1]}
            setAdicionarVenda={setAdicionarVenda}
            gravar={gravar}
          />
      }
    </s.ContainerGeral>

  )
}
