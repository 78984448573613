import styled from "styled-components";

export const LoadChamarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  align-items: center;
  justify-content: center;
  background-color: #8282829c;
  z-index: 99999999;
  flex-direction: column;
  font-size: 3.5rem;
  font-family: Inter, Rajdhani, sans-serif;
`;
