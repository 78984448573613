import styled from 'styled-components';
import { destaque5 } from '../../styles/coresPadroes';

//div pai
export const divPai = styled.div`
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 99999999999999999999;
`;

//div título
export const divTitulo = styled.div`
    width: 100%;
    height: auto;
    background-color: ${destaque5};
    text-align: center;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    z-index: 1000000000;
    margin-bottom: 10px;

    @media (max-width: 500px) {
        h1{
            font-size: 16px;
        }
        
    }
`

//div filho
export const divFilho = styled.div`
    flex: 1;
    overflow: auto;
`

//fechar tela
export const btnFechar = styled.div`
    position: absolute;
    top: 5px;
    right: 10px;
    color: #ee1727;
    cursor: pointer;
    font-family: Arial;
    font-size: 18px;
    font-weight: 900;
    padding: 1px;
    text-shadow: 1px 1px #ee1727;
`


