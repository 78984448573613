import React from 'react'
import * as s from './estiloRelatorios'

function ProdutosCategoria() {

  function imprimir() {
    window.print()
  }

  return (
    <s.ContainerGeral>
    <MenuAcoes imprimir={true} onClickImprimir={() => imprimir()} />

    <s.SubContainerGeral>ProdutosCategoria</s.SubContainerGeral>
  </s.ContainerGeral>
  )
}

export default ProdutosCategoria